
import { getGlobalAssetsBases } from './assets';

// Nota: caso houver slugs com um mesmo symbol, assume-se que serão em corretoras diferentes,
// então se caso for necessário, informe o exchange_slug e filtre as exceções
export const getAssetBase = ({ symbol = null, name = null, slug = null }) => {
    const bases = getGlobalAssetsBases({});
    let base = { symbol, name: name ?? symbol, slug };
    let filteredBase = [];

    if (bases) {
        if (symbol) {
            symbol = symbol.toUpperCase();
            filteredBase = bases.filter(base => base.symbol.toUpperCase() == symbol);
        }
        else if (name) {
            name = name.toUpperCase();
            filteredBase = bases.filter(base => base.name.toUpperCase() == name);
        }
        else if (slug) {
            slug = slug.toUpperCase();
            filteredBase = bases.filter(base => base.slug.toUpperCase() == slug);
        }
    }

    if (filteredBase.length > 0) return filteredBase[0];

    return base;
};