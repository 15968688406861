/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import i18n from 'locales/i18n';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';

import {
    Page, Layout, Loading, MessagePopup, Row, Column, Panel,
    SwitchableIcon, Title
} from 'components/imports';

import { RefreshSvg } from 'icons/imports';

import { timeframes } from 'constants';

import { getAssetBase } from 'utils/assets-bases';
import { getAssetQuote } from 'utils/assets-quotes';

import { getUserConnectedExchanges } from 'utils/exchanges';
import { getTrendsMarketcaps } from 'utils/trends-marketcaps';

import {
    getSelectedCurrency, getSelectedExchange, getSelectedTimeframe, getLanguage,
    getTheme, getTop100Filter
} from 'utils/cookies';

import usePriceWatcher from 'hooks/usePriceWatcher';

import './AtsSettings.css';


const AtsSettings = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'ats-settings';

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);

    const [appLang] = useState(getLanguage());
    const [appTheme] = useState(getTheme());
    const [appSelectedExchange, setAppSelectedExchange] = useState(getSelectedExchange());
    const [appSelectedCurrency, setAppSelectedCurrency] = useState(getSelectedCurrency());
    const [appSelectedTimeframe, setAppSelectedTimeframe] = useState(getSelectedTimeframe());
    const [appTop100Filter, setAppTop100Filter] = useState(getTop100Filter());

    const [changingFilters, setChangingFilters] = useState(false);

    const [popUpDuration,] = useState(3000);
    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');

    const [exchanges,] = useState(getUserConnectedExchanges({}));

    const [tickers, setTickers] = useState({});

    const [loadingTickers, setLoadingTickers] = useState(true);
    const [tickerTapeCoins, setTickerTapeCoins] = useState([]);


    // usePriceWatcher(isUserAuthenticated, userExchanges, appSelectedCurrency, appSelectedTimeframe, setTickers);


    useEffect(() => {
        if (location.pathname === `/${pageId}`)
            document.title = `${t('ats-settings')} - Smart Trade`;
    }, [location.pathname]);


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {

        if (!(appSelectedExchange in tickers)) return;

        let trendsMarketcaps = {};

        if (appTop100Filter) {
            trendsMarketcaps = getTrendsMarketcaps({ asObject: true });
        }

        let tickerList = Object.entries(tickers[appSelectedExchange]).reduce((tickers, [pair, ticker]) => {
            const [base,] = pair.split('/');

            if (base.includes('USD')) return tickers;

            ticker.symbol = base;

            if (appTop100Filter && trendsMarketcaps) {
                let baseAsset = getAssetBase({ symbol: base });
                if (!(baseAsset.slug in trendsMarketcaps)) return tickers;
            }

            ticker.pnl = ticker.pnls[appSelectedTimeframe][1];

            tickers.push(ticker);

            return tickers;
        }, []);

        if (!tickerList.length) return;

        let exchangeTickers = tickers[appSelectedExchange];

        let updateTickerTapeCoins = tickerTapeCoins.map((ticker) => {

            if (!(ticker.pair in exchangeTickers)) return ticker;

            ticker.price = exchangeTickers[ticker.pair].price;
            ticker.pnl = exchangeTickers[ticker.pair].pnls[appSelectedTimeframe][1];

            return ticker;
        });

        setTickerTapeCoins(updateTickerTapeCoins);

    }, [tickers]);


    return (
        <Page id={pageId}>
            <Loading id={'overview'} />

            <MessagePopup
                level={popUpLevel}
                text={popUpText}
                duration={popUpDuration}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='basic'
                setIsUserAuthenticated={setIsUserAuthenticated}
                showToolbar={true}
                showHeader={true}
                tickerTapeCoins={tickerTapeCoins.filter((ticker) => ticker.price > 0).slice(0, 10)}
            >
                
            </Layout>
        </Page>
    );
};

export default AtsSettings;
