import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Cropper from './Cropper';
import { RotationSlider, ZoomSlider } from './Sliders';
import { useImageCropContext } from 'providers/ImageCropProvider';

import { Button, Column, Row, Title } from 'components/imports';

import { UserDefaultPic128Png } from 'icons/imports';

import { readFile } from 'utils/CropImage';

import { Modal } from 'antd';

import './Cropper.css';


const ImageCrop = (props) => {

    const { t } = useTranslation();

    const [openModal, setOpenModal] = useState(false);
    const [preview, setPreview] = useState(UserDefaultPic128Png);

    const { getProcessedImage, setImage, resetStates } = useImageCropContext();

    useEffect(() => {
        if (props.avatar instanceof File) 
            setPreview(window.URL.createObjectURL(props.avatar));
        else setPreview(props.avatar);
    }, [props.avatar]);

    const handleDone = async () => {
        const avatar = await getProcessedImage();
        setPreview(window.URL.createObjectURL(avatar));
        props.setAvatar(avatar);
        resetStates();
        setOpenModal(false);
    };

    const handleFileChange = async ({ target: { files } }) => {
        const file = files && files[0];
        const imageDataUrl = await readFile(file);

        if (!imageDataUrl) return;

        setImage(imageDataUrl);
        setOpenModal(true);
    };

    const handleFileRechange = async ({ target: { files } }) => {
        const file = files && files[0];
        const imageDataUrl = await readFile(file);
        setImage(imageDataUrl);
    };

    return (
        <>
            <input
                accept='image/*'
                className='hidden'
                onChange={handleFileChange}
                id='avatar-input'
                type='file'
            />

            <label
                id='user-profile-pic'
                htmlFor='avatar-input'
                title={t('upload-profile-pic')}
            >
                <img
                    src={preview}
                    height={240}
                    width={240}
                    alt=''
                />
            </label>

            <Modal
                className='fit-all'
                open={openModal}
                footer={null}
            >
                <Title
                    txt={t('update-profile-pic')}
                    variation='secondary'
                />

                <hr />

                <Column>
                    <Row
                        fill='width'
                        j='center'
                    >
                        <div className='crop-container'>
                            <Cropper />
                        </div>
                    </Row>

                    <ZoomSlider />

                    <RotationSlider />

                    <input
                        type='file'
                        multiple
                        onChange={handleFileRechange}
                        className='hidden'
                        id='avatar-input'
                        accept='image/*'
                    />

                    <Button
                        className='fill-width'
                        id='profile-pic-rechoose'
                        variation='secondary'
                    >
                        <label
                            htmlFor='avatar-input'
                            style={{ marginBottom: 0 }}
                        >
                            {t('upload-another-pic')}
                        </label>
                    </Button>

                    <Row
                        fill='width'
                    >
                        <Button
                            className='fill-width'
                            id='profile-pic-cancel'
                            onClick={() => setOpenModal(false)}
                            variation='secondary'
                        >
                            Cancel
                        </Button>

                        <Button
                            className='fill-width'
                            id='profile-pic-send'
                            onClick={handleDone}
                            variation='primary'
                        >
                            Send
                        </Button>
                    </Row>
                </Column>
            </Modal>
        </>
    );
};


ImageCrop.propTypes = {
    avatar: PropTypes.string.isRequired,
    setAvatar: PropTypes.func.isRequired,
};


export default ImageCrop;
