/* eslint-disable no-undef */

export const appEnv = process.env.REACT_APP_ENV || 'development';

export const defaults = {
    currency: 'USDT',
    exchange: 'binance',
    language: 'en-US',
    timeframe: '24h',
    redirectTime: {
        notFound: 5 * 1000, // milliseconds
        unauthorized: 5 * 1000
    },
    refreshTime: {
        balances: 30 * 1000,
        news: 30 * 1000,
        trades: 30 * 1000
    },
    theme: 'dark',
};

export const currencies = {
    'USDT': 'UDST Tether', 
    'BTC': 'BTC Bitcoin',
    'BRL': 'BRL Brazilian Real'
};

export const exchangesPasswordRequired = ['KuCoin'];

export const timeframes = ['1d', '4h', '1h', '30m'];
export const tickersTimeframes = ['24h', '4h', '1h'];

export const disregard = {
    symbols: []
};

export const languages = {
    'de-DE': { label: 'Deutsch', icon: 'DE' },
    'en-US': { label: 'English', icon: 'EN' },
    'es-ES': { label: 'Español', icon: 'ES' },
    'fr-FR': { label: 'Français', icon: 'FR' },
    'hi-IN': { label: 'हिन्दी', icon: 'HI' },
    'hu-HU': { label: 'Magyar', icon: 'HU' },
    'it-IT': { label: 'Italiano', icon: 'IT' },
    'ja-JP': { label: '日本語', icon: 'JP' },
    'ko-KR': { label: '한국어', icon: 'KR' },
    'po-PL': { label: 'Polski', icon: 'PO' },
    'pt-BR': { label: 'Português', icon: 'PT' },
    'ru-RU': { label: 'Русский', icon: 'RU' }
};

export const pagesWithToolbar = ['overview', 'dashboard', 'signals', 'news', 'wallet'];

export const proxyURL = 'https://cors-anywhere.herokuapp.com/';

export const userService = {
    hosts: {
        development: 'http://localhost:7000',
        staging: 'https://users.staging.we-bronx.io',
        production: 'https://users.we-bronx.io'
    },
    version: 'v1'
};

export const userServiceWs = {
    hosts: {
        development: 'ws://localhost:7000',
        staging: 'wss://users.staging.we-bronx.io',
        production: 'wss://users.we-bronx.io'
    },
    version: 'v1'
};

export const userServiceHost = userService.hosts[appEnv];
export const userServiceVersion = userService.version;

export const userServiceWsHost = userServiceWs.hosts[appEnv];
export const userServiceWsVersion = userServiceWs.version;

export const smallBalance = 1;

export const storageBuckets = {
    development: 'https://storage.googleapis.com/bnx-staging-icons',
    staging: 'https://storage.googleapis.com/bnx-staging-icons',
    production: 'storage.googleapis.com/bnx-production-icons'
};

export const storageBucket = storageBuckets[appEnv];