import { useEffect, useRef } from 'react';

import PriceWatcher from '../utils/priceWatcher';  // Importe a classe correta

import { exchangesTickersGet } from '../apis/endpoints/exchangesTickersGet';


const usePriceWatcher = (isUserAuthenticated, userExchanges, appSelectedCurrency, appSelectedTimeframe, setTickers, connectWs = true) => {
    const priceWatcherRef = useRef(null);

    useEffect(() => {
        // Inicializar a instância do PriceWatcher uma única vez
        if (!priceWatcherRef.current) {
            priceWatcherRef.current = new PriceWatcher();
        }

        // Verificar se as condições estão satisfeitas para iniciar o WebSocket
        if (isUserAuthenticated) {
            // Parar o WebSocket anterior se existir
            if (priceWatcherRef.current) {
                priceWatcherRef.current.stop();
            }

            const userExchangesCommaSeparated = userExchanges.map((exchange) => exchange.slug).join(',');

            exchangesTickersGet({
                exchangeSlugs: userExchangesCommaSeparated,
                timeframe: appSelectedTimeframe
            }).then((result) => {
                const { response, isSuccess } = result;

                if (isSuccess) setTickers(response);

                if (connectWs) {
                    console.log('Starting price watcher');

                    // Iniciar o watcher para a exchange selecionada
                    priceWatcherRef.current.start({
                        userExchanges: userExchangesCommaSeparated,
                        targetCurrency: appSelectedCurrency,
                        targetTimeframe: appSelectedTimeframe,
                        onUpdate: (data) => {
                            // Use um callback seguro para setar o estado
                            setTickers(data);
                        },
                    });
                }
            });
        }

        // Cleanup: parar o WebSocket quando o componente for desmontado ou as dependências mudarem
        return () => {
            if (priceWatcherRef.current) {
                console.log('Stopping price watcher');
                priceWatcherRef.current.stop();
            }
        };
    }, [isUserAuthenticated, userExchanges, userExchanges, appSelectedCurrency, appSelectedTimeframe, setTickers]);
};

export default usePriceWatcher;
