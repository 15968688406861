import PropTypes from 'prop-types';
import $ from 'jquery';

import qs from 'qs';

import { appEnv, userService as users } from 'constants';

import { getAuthorization } from 'utils/cookies';


const exchangesOhlcvGet = async (props) => {

    const { exchange, limit, since, symbol, timeframe, until } = props;

    const method = 'get';
    const { hosts, version } = users;

    const host = hosts[appEnv];

    var queryObj = { symbol, timeframe, since, until, limit };
    if (limit) queryObj['limit'] = limit;
    queryObj['list_of_dict'] = false;

    const query = qs.stringify(queryObj);

    const url = `${host}/api/${version}/exchanges/${exchange}/ohlcv?${query}`;

    const auth = await getAuthorization();

    var settings = {
        url: url,
        method: method,
        timeout: 0,
        processData: false,
        contentType: false,
        beforeSend: (xhr) => xhr.setRequestHeader('Authorization', auth)
    };

    try {
        const response = await $.ajax(settings);
        const parsedResponse = JSON.parse(response);

        return { isSuccess: true, response: parsedResponse };

    } catch (error) {
        let status = 0;
        let errorType = '';


        const response = JSON.parse(error.responseText);

        if (error && error.status) {

            status = error.status;

            try {
                if ('detail' in response && 'type' in response.detail) {
                    errorType = response.detail.type;
                }
            } catch (e) {
                errorType = 'InternalServerError';
            }
        }

        let detail = response.detail;

        return {
            isSuccess: false,
            errorType,
            detail,
            status,
        };
    }
};


exchangesOhlcvGet.propTypes = {
    exchange: PropTypes.string.isRequired,
    limit: PropTypes.number,
    since: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    timeframe: PropTypes.string.isRequired,
    until: PropTypes.number.isRequired,
};


export { exchangesOhlcvGet };
