
import React from 'react';
import PropTypes from 'prop-types';

import { Column, Date, Row } from 'components/imports';
import { getIcon } from 'icons/imports';

import { directionTermometer, geFormattedSentiment, getTranslateFromDirection } from 'utils/general';

import './Card.css';


const Card = (props) => {

    switch (props.variation) {
        case 'article-preview':

            var maxLen = props.showAllTitle ? 500 : props.banner ? 50 : 75;
            var title = props?.title ?? '';
            
            title = title.length >= maxLen ? title.substring(0, maxLen - 3) + '...' : title;

            return (
                <div
                    className='card container article-preview fill-width'
                    id={props.id}
                    onClick={props.onClick}
                >
                    <Column fill='all'>
                        <Row className='banner-container' fill='width'>
                            <img className='banner' src={props.banner} />
                        </Row>

                        <Row
                            a='center'
                            className='card-header'
                            fill='width'
                            j='between'
                        >
                            <img
                                className="source-icon"
                                src={props.sourceIcon}
                                title={props.source}
                            />

                            <Date
                                timestamp={props.date}
                                lang={props.lang}
                                variation='dynamic'
                            />
                        </Row>

                        <Row fill='width'>
                            <h2 title={props.title}>{title}</h2>
                        </Row>
                    </Column>
                </div>
            );

        case 'coin-trend-direction':

            var translateDir = getTranslateFromDirection(props.direction);
            var formattedDir = geFormattedSentiment(props.direction);

            return (
                <div
                    className='card container coin-trend-direction fill-width'
                    id={props.id}
                >
                    <Row fill='all'>
                        <Column fill='width'>
                            <Row id='card-row-0' fill='width'>
                                <div id='card-row-0-0'>
                                    <img src={getIcon(props.coinIcon.toLowerCase(), '128PNG')} />
                                </div>

                                <Column fill='width'>
                                    <p className="card-symbol"><b>{props.symbol}</b></p>
                                    <p className="card-title">{props.title.toUpperCase()}</p>
                                </Column>
                            </Row>
                        </Column>
                        <Row j='end'>
                            <Row align='center'>
                                <p className={`card-direction ${formattedDir}`}><b>{translateDir}</b></p>
                            </Row>
                            <Row >
                                {directionTermometer(formattedDir)}
                            </Row>
                        </Row>
                    </Row>
                </div>
            );

        case 'info':

            return (
                <div
                    className={'card container info' + (props.className ? ' ' + props.className : '')}
                    id={props.id}
                >
                    {props.children}
                </div>
            );

        default:
            return (
                <div
                    className={'card container' + (props.variation ? ' ' + props.variation : '')}
                    id={props.id}
                >
                    {props.children}
                </div>
            );
    }
};

Card.propTypes = {
    banner: PropTypes.string,
    change: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string,
    coinIcon: PropTypes.string,
    date: PropTypes.number,
    direction: PropTypes.string,
    fromNow: PropTypes.object,
    id: PropTypes.string.isRequired,
    index: PropTypes.number,
    lang: PropTypes.string,
    onClick: PropTypes.func,
    price: PropTypes.number,
    refCoinIcon: PropTypes.string,
    showAllTitle: PropTypes.bool,
    source: PropTypes.string,
    sourceIcon: PropTypes.string,
    symbol: PropTypes.string,
    title: PropTypes.string,
    trend: PropTypes.string,
    variation: PropTypes.string,
    url: PropTypes.string,
};


export default Card;
