/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';
import PropTypes from 'prop-types';

import packageJson from '../../../../package.json';

import { useTranslation } from 'react-i18next';
import i18n from 'locales/i18n';

import { Segmented, Select } from 'antd';

import {
    Column, Header, MessagePopup, Row, SwitchableIcon,
    Ticker, Title, Toolbar, MfaModal
} from 'components/imports';

import { BnxIconLogoSvg, SunSvg, MoonSvg } from 'icons/imports';
import { SmartTradeLogoSvg } from 'images/imports';

import {
    getSelectedCurrency as getCookieCurrency, setSelectedCurrency as setCookieCurrency,
    getLanguage as getCookieLanguage, setLanguage as setCookieLanguage,
    getSelectedExchange as getCookieExchange, setSelectedExchange as setCookieExchange,
    getSelectedTimeframe as getCookieTimeframe, setSelectedTimeframe as setCookieTimeframe,
    getTop100Filter as getCookieTop100Filter, setTop100Filter as setCookieTop100Filter,
    getTheme as getCookieTheme, setTheme as setCookieTheme
} from 'utils/cookies';

import { fetchAndSaveInStorageGlobalAssets } from 'utils/assets';
import { fetchAndSaveInStorageGlobalExchanges, fetchAndSaveInStorageUserConnectedExchanges, getUserConnectedExchanges } from 'utils/exchanges';
import { fetchAndSaveInStorageUserAtsPairs } from 'utils/user-ats-pairs';

import { pageLoaded } from 'utils/pages';
import { checkAuthorization } from 'utils/token';

import { sessionsMeMfaGet } from 'apis/imports';

import { languages, tickersTimeframes } from 'constants';

import Skeleton from 'react-loading-skeleton';

import './Layout.css';


class QuickSettingsTypes {
    static BASIC = 'basic'; // Theme Toggler
    static MINIMAL = 'minimal'; // Language Selector and Theme Toggler
    static FULL = 'full'; // Exchange Selector, Language Selector and Theme Toggler
}


const Layout = (props) => {

    // Get version from package.json
    const version = packageJson.version;

    const checkAuth = props.checkAuth;
    const pageId = props.page;
    const quickSettings = props.quickSettings || QuickSettingsTypes.BASIC;
    const showHeader = props.showHeader || false;
    const showSegmented = props.showSegmented || false;
    const showToolbar = props.showToolbar || false;
    const showTopLogo = props.showTopLogo || false;
    const showVersion = props.showVersion || false;
    const segmentedOptions = props.segmentedOptions || [];
    const segmentedValue = props.segmentedValue || '';
    const segmentedOnChange = props.segmentedOnChange || (() => { });
    const tickerTapeCoins = props.tickerTapeCoins;

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

    const [userExchanges, setUserExchanges] = useState(getUserConnectedExchanges({}));

    const [language, setLanguage] = useState(getCookieLanguage());
    const [selectedExchange, setSelectedExchange] = useState(getCookieExchange());
    const [selectedCurrency, setSelectedCurrency] = useState(getCookieCurrency());
    const [selectedTimeframe, setSelectedTimeframe] = useState(getCookieTimeframe());
    const [theme, setTheme] = useState(getCookieTheme());
    const [top100Filter, setTop100Filter] = useState(getCookieTop100Filter() == 'true');

    const [isMfaModalOpen, setIsMfaModalOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [mfaModalVerifyButtonState, setMfaModalVerifyButtonState] = useState('enabled');

    const popUp = props.popUp || { level: 'success', text: '', duration: 0 };

    const moonIcon = <MoonSvg className='icon-svg' />;
    const sunIcon = <SunSvg className='icon-svg' />;

    useEffect(() => {
        $(document).scrollTop = 0;
        $('html, body').animate({ scrollTop: 0 }, 1);

        let $body = document.body.classList;
        $body.remove(...$body);
        $body.add(pageId + '-page');

        localStorage.setItem('lastVisitedPage', location.pathname);

        changeSelectedCurrency();
        changeSelectedExchange();
        changeLanguage();
        changeTheme();

        if (checkAuth) {
            checkAuthorization(navigate, setIsMfaModalOpen, setUsername, setIsUserAuthenticated);
        }

        fetchAndSaveInStorageGlobalAssets();
        fetchAndSaveInStorageGlobalExchanges();

        pageLoaded(pageId);
    }, [location.pathname]);


    useEffect(() => {
        if (isUserAuthenticated) {

            if (props.setIsUserAuthenticated)
                props.setIsUserAuthenticated(isUserAuthenticated);

            fetchAndSaveInStorageUserConnectedExchanges().then(() => setUserExchanges(getUserConnectedExchanges({})));
            fetchAndSaveInStorageUserAtsPairs({ mode: 'default' });
            fetchAndSaveInStorageUserAtsPairs({ mode: 'simulation' });
        }
    }, [isUserAuthenticated]);


    useEffect(() => {
        if (props.setUserExchanges)
            props.setUserExchanges(userExchanges);
    }, [userExchanges]);

    const changeSelectedCurrency = (newSelectedCurrency = selectedCurrency) => {
        setSelectedCurrency(newSelectedCurrency);
        setCookieCurrency(newSelectedCurrency);
    };


    const changeSelectedExchange = (newSelectedExchange = selectedExchange) => {
        setSelectedExchange(newSelectedExchange);
        if (props.setSelectedExchange)
            props.setSelectedExchange(newSelectedExchange);
        setCookieExchange(newSelectedExchange);
    };


    const changeSelectedTimeframe = (newSelectedTimeframe = selectedTimeframe) => {
        setSelectedTimeframe(newSelectedTimeframe);
        if (props.setSelectedTimeframe)
            props.setSelectedTimeframe(newSelectedTimeframe);
        setCookieTimeframe(newSelectedTimeframe);
    };


    const changeTop100Filter = (newTop100Filter = top100Filter) => {
        setTop100Filter(newTop100Filter);
        if (props.setTop100Filter)
            props.setTop100Filter(newTop100Filter);
        setCookieTop100Filter(newTop100Filter);
    };


    const changeLanguage = (newLang = language) => {
        setLanguage(newLang);
        setCookieLanguage(newLang);
        // setCurrentStep(3);
        i18n.changeLanguage(newLang);
    };


    const changeTheme = (newTheme = theme) => {
        setTheme(newTheme);
        setCookieTheme(newTheme);

        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(newTheme);
    };

    const handleMfaModalSubmit = async (oauthmfa) => {

        setMfaModalVerifyButtonState('loading');

        var mfa_type = 'TOTP';

        if (oauthmfa === '') {
            setMfaModalVerifyButtonState('error');
            return;
        }

        // Validations to avoid unecessary API calls
        if (oauthmfa.length !== 6) {
            setMfaModalVerifyButtonState('error');
            return;
        }

        setMfaModalVerifyButtonState('loading');

        let result = await sessionsMeMfaGet({
            oauthmfa,
            mfa_type
        });

        $('form #check-code-button').css('cursor', 'pointer');
        if (result.isSuccess) {
            setMfaModalVerifyButtonState('success');
            window.location.reload();
        } else {
            setMfaModalVerifyButtonState('error');
        }
    };


    const buildExchangesFilter = () => {
        let options = Object.values(userExchanges).sort((a, b) => a.name.localeCompare(b.name));
        return options.map((exchange) => {
            return { label: exchange.name, value: exchange.slug };
        });
    };


    const buildTimeframeFilter = () => {
        return tickersTimeframes.map((timeframe) => {
            return { label: timeframe, value: timeframe };
        });
    };


    const buildLanguagesFilter = () => {
        return Object.entries(languages).map(([key, lang]) => {
            return { label: lang.label, value: key };
        });
    };

    return (
        <div className={props.page + ' layout browser'} style={{ 'display': 'none' }}>

            <MessagePopup level={popUp.level} text={popUp.text} duration={popUp.duration} />

            <MfaModal
                isOpen={isMfaModalOpen}
                handleSubmit={handleMfaModalSubmit}
                setIsOpen={setIsMfaModalOpen}
                username={username}
                verifyButtonState={mfaModalVerifyButtonState}
            />

            <div className='all-page-content'>

                {showToolbar ? <Toolbar page={props.page} /> : <></>}

                <Column fill='all' m='pri-ver'>
                    {showHeader
                        ? <Header variation='primary'>
                            <Row
                                className='header-section'
                            >
                                <Title variation='primary' txt={t(pageId)} id={`step-${pageId}-title`} />

                                {showSegmented
                                    ? <Segmented
                                        options={segmentedOptions}
                                        value={segmentedValue}
                                        onChange={segmentedOnChange}
                                    />
                                    : <></>
                                }
                            </Row>

                            {tickerTapeCoins &&
                                (tickerTapeCoins.length > 0 ?
                                    <Row
                                        fill='width'
                                        j='center'
                                    >
                                        <Ticker items={tickerTapeCoins} />
                                    </Row>
                                    : <Skeleton
                                        borderRadius={'12px'}
                                        height='2rem'
                                        width='40rem'
                                    />)
                            }

                            <Row
                                className='header-section'
                                j='end'
                            >
                                {quickSettings === QuickSettingsTypes.FULL
                                    ? <>
                                        {/* <Select
                                            className={'currency-dd'}
                                            id={'choose-currency'}
                                            lang={language}
                                            onSelect={changeSelectedCurrency}
                                            options={Object.entries(currencies)}
                                            selected={selectedCurrency}
                                        /> */}

                                        <label className='row g-0' style={{ marginBottom: '0' }}>
                                            <span className='input-checkbox-container' style={{ width: '1.2rem !important', borderColor: 'white' }}>
                                                <input
                                                    checked={top100Filter}
                                                    name='in-wallet-check'
                                                    id='in-wallet-check'
                                                    onChange={() => changeTop100Filter(!top100Filter)}
                                                    type='checkbox'
                                                />
                                                <span className='checkmark'></span>
                                            </span>

                                            <p>{t('top-100-cryptos')}</p>
                                        </label>

                                        <Select
                                            id='choose-exchange'
                                            placeholder={t('exchange.s')}
                                            variant='borderless'
                                            defaultValue={selectedExchange}
                                            style={{ width: '8rem' }}
                                            options={buildExchangesFilter()}
                                            onChange={(value) => changeSelectedExchange(value)}
                                        />

                                        <Select
                                            id='choose-timeframe'
                                            placeholder={t('timeframe.s')}
                                            variant='borderless'
                                            defaultValue={selectedTimeframe}
                                            style={{ width: '5rem' }}
                                            options={buildTimeframeFilter()}
                                            onChange={(value) => changeSelectedTimeframe(value)}
                                        />
                                    </>
                                    : <></>}

                                {quickSettings === QuickSettingsTypes.MINIMAL
                                    ? <></>
                                    : (<Select
                                        placeholder={t('language.s')}
                                        variant='borderless'
                                        defaultValue={language}
                                        style={{ width: '8rem' }}
                                        options={buildLanguagesFilter()}
                                        onChange={(value) => changeLanguage(value)}
                                    />)}

                                <SwitchableIcon id="theme-switch-step"
                                    onImage={sunIcon}
                                    isOn={theme === 'dark'}
                                    offImage={moonIcon}
                                    onToggle={() => changeTheme(theme === 'dark' ? 'bright' : 'dark')}
                                />
                            </Row>
                        </Header>
                        : (showTopLogo
                            ? <Row
                                fill='width'
                                j='between'
                            >
                                <img id='smart-trade-logo' src={SmartTradeLogoSvg} />

                                <Row
                                    className='quick-settings'
                                    j='end'
                                >
                                    (<Select
                                        placeholder={t('language.s')}
                                        variant='borderless'
                                        defaultValue={languages[language]?.icon}
                                        style={{ width: '10rem' }}
                                        options={buildLanguagesFilter()}
                                        onChange={(value) => changeLanguage(value)}
                                    />)

                                    <SwitchableIcon id="theme-switch-step"
                                        onImage={sunIcon}
                                        isOn={theme === 'dark'}
                                        offImage={moonIcon}
                                        onToggle={() => changeTheme(theme === 'dark' ? 'bright' : 'dark')}
                                    />
                                </Row>
                            </Row>
                            : <Row
                                className='quick-settings basic'
                                fill='width'
                                j='between'
                                m='pri-hor'
                            >
                                <Row id='logo'>
                                    <img id='bnx-logo-header' src={BnxIconLogoSvg} />

                                    <img id='smart-trade-logo-header' src={SmartTradeLogoSvg} />
                                </Row>

                                <Row>
                                    <Select
                                        placeholder={t('language.s')}
                                        variant='borderless'
                                        defaultValue={languages[language]?.icon}
                                        style={{ width: '10rem' }}
                                        options={buildLanguagesFilter()}
                                        onChange={(value) => changeLanguage(value)}
                                    />

                                    <SwitchableIcon id="theme-switch-step"
                                        onImage={sunIcon}
                                        isOn={theme === 'dark'}
                                        offImage={moonIcon}
                                        onToggle={() => changeTheme(theme === 'dark' ? 'bright' : 'dark')}
                                    />
                                </Row>
                            </Row>)}

                    {props.children}
                </Column>
            </div>

            {showVersion ? <p className='version'>v{version}</p> : <></>}
        </div>
    );
};


Layout.propTypes = {
    checkAuth: PropTypes.bool.isRequired,
    children: PropTypes.node,
    popUp: PropTypes.object,
    page: PropTypes.string.isRequired,
    quickSettings: PropTypes.string,
    segmentedOnChange: PropTypes.func,
    segmentedOptions: PropTypes.array,
    segmentedValue: PropTypes.string,
    setIsUserAuthenticated: PropTypes.func,
    setSelectedCurrency: PropTypes.func,
    setSelectedExchange: PropTypes.func,
    setSelectedTimeframe: PropTypes.func,
    setTop100Filter: PropTypes.func,
    setUserExchanges: PropTypes.func,
    showHeader: PropTypes.bool,
    showSegmented: PropTypes.bool,
    showToolbar: PropTypes.bool,
    showTopLogo: PropTypes.bool,
    showVersion: PropTypes.bool,
    tickerTapeCoins: PropTypes.array
};


export default Layout;
