/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import i18n from 'locales/i18n';
import $ from 'jquery';

import { useTranslation } from 'react-i18next';

import { useTour } from '@reactour/tour';
import { stepsSettings, stepsSettingsWithoutMfa } from 'steps';

import {
    Loading,
    MessagePopup, SwitchableIcon
} from 'components/imports';
import { ConfirmSvg, LoadingSvg, PencilSvg, UserDefaultPic128Png } from 'icons/imports';
import {
    exchangesConnectionsMeDelete, exchangesConnectionsMeGet,
    qrCodesMeGet,
    usersMeGet, usersMePatch, usersMeAvatarGet, usersMeAvatarPatch
} from 'apis/imports';

import { Button, Column, Layout, Page, Row } from 'components/imports';
import { Input, List, Panel, Title } from 'components/imports';

import { getLanguage, getTheme, setSelectedExchange } from 'utils/cookies';
import { getGlobalExchanges, getUserConnectedExchanges } from 'utils/exchanges';
import { abbreviateText } from 'utils/general';

import { getImage } from 'images/imports';

import ImageCrop from './components/Cropper/ImageCrop';
import ImageCropProvider from 'providers/ImageCropProvider';
import ExchangeConnectionsModal from './components/ExchangeConnectionsModal';


import { exchangesPasswordRequired } from 'constants';

import './Settings.css';


const Settings = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'settings';

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);

    const [appLang,] = useState(getLanguage());
    const [appTheme,] = useState(getTheme());

    const [avatar, setAvatar] = useState(UserDefaultPic128Png);

    const [fullname, setFullname] = useState('-');
    const [username, setUsername] = useState('-');

    const [showQrCode, setShowQrCode] = useState(false);
    const [mfaQRCode, setMfaQRCode] = useState();

    const [editFullname, setEditFullname] = useState(false);
    const [newFullname, setNewFullname] = useState('-');

    const [changePassword, setChangePassword] = useState(false);
    const [someChange, setSomeChange] = useState(false);

    // Exchange Connections
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [exchanges,] = useState(getGlobalExchanges({}));

    const [userExchanges, ] = useState(getUserConnectedExchanges({}));
    const [exchangeConnections, setExchangeConnections] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');
    const [popUpDuration, setPopUpDuration] = useState(3500);
    const { setIsOpen, setSteps, setCurrentStep } = useTour();

    const $fullname = $('#user-fullname-input');

    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [isPatchingUser, setIsPatchingUser] = useState(false);
    const [isLoadingExchangeConnections, setIsLoadingExchangeConnections] = useState(true);
    const [addExchangeConnButtonState, setAddExchangeConnButtonState] = useState('enabled');
    const [showMfaQrCodeButtonState, setShowMfaQrCodeButtonState] = useState('enabled');


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('settings')} - Smart Trade`;

            $('#user-fullname-input').hide();
            $('#user-fullname-label').hide();
            $('#mfa-code-message').hide();
            $('#mfa-code-img').hide();
            $('.message-popup-container').fadeOut();

            fetchApi();
            getAvatar();
        }
    }, [location.pathname]);


    useEffect(() => {
        if (exchanges.length == exchangeConnections.length)
            setAddExchangeConnButtonState('disabled');
        else
            setAddExchangeConnButtonState('enabled');
    }, [exchangeConnections]);


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {
        patchAvatar();
    }, [avatar]);


    useEffect(() => {
        $('.settings-container .email-text').prop('title', username);
        $('.settings-container .email-text').text(
            username.length > 25 ? abbreviateText(username, 22).replace('...', '') + '...' : username);
    }, [username]);


    useEffect(() => {
        $('.settings-container .fullname-text').prop('title', fullname);
        $('.settings-container .fullname-text').text(
            fullname.length > 25 ? abbreviateText(fullname, 22).replace('...', '') + '...' : fullname);
    }, [fullname]);


    useEffect(() => {
        if (fullname !== newFullname) {
            setSomeChange(true);
            setFullname(newFullname.trim());
        }
    }, [editFullname]);


    useEffect(() => {
    }, [changePassword]);


    useEffect(() => {
        // Send Patch request if user closed edit mode
        if (!editFullname && someChange) patchUser();
    }, [editFullname, someChange]);


    const getAvatar = async () => {
        if (!isUserAuthenticated) return;

        const userAvatarResult = await usersMeAvatarGet();

        if (userAvatarResult && userAvatarResult.response) {
            setAvatar(userAvatarResult.response.avatar_url);
        }
    };


    const patchAvatar = async () => {

        if (!isUserAuthenticated) return;

        if (!avatar || !(avatar instanceof File)) return;

        const formData = new FormData();
        formData.append('avatar', avatar);

        const result = await usersMeAvatarPatch({ image: avatar, imageType: avatar.type });

        if (result.isSuccess) {
            setPopUpLevel('success');
            setPopUpText(t('success-avatar-update'));
            setPopUpDuration(2000);

            setTimeout(() => window.location.reload(), 2000);
        }
        else {
            setPopUpLevel('error');
            setPopUpText(t('error-avatar-update'));
            setPopUpDuration(3500);
        }
    };


    const fetchApi = async () => {

        if (!isUserAuthenticated) return;

        await fetchUserData();
        await fetchExchangeConnections();

        setIsLoadingPage(false);
    };


    const fetchMfaQrCode = async () => {

        setShowMfaQrCodeButtonState('loading');

        const result = await qrCodesMeGet();

        if (result.isSuccess) {
            setShowMfaQrCodeButtonState('success');
            const imageBlob = result.response;
            const imageObjectURL = URL.createObjectURL(imageBlob);
            setMfaQRCode(imageObjectURL);
            setShowQrCode(true);

            return;
        }

        setShowMfaQrCodeButtonState('error');

        setPopUpLevel('error');
        setPopUpText(t('error-fetch-mfa-qr-code'));
        setPopUpDuration(3500);
    };


    const patchUser = async () => {

        setSomeChange(false);
        setIsPatchingUser(true);

        const result = await usersMePatch({ body: { fullname } });

        setIsPatchingUser(false);

        if (result.isSuccess) {
            setPopUpLevel('success');
            setPopUpText(t('success-fullname-change'));
            setPopUpDuration(2000);

            setTimeout(() => {
                window.location.reload();
            }, 2000);
            return;
        }

        setPopUpLevel('error');
        setPopUpText(t('error-fullname-change'));
        setPopUpDuration(3500);
    };


    const fetchUserData = async () => {
        const result = await usersMeGet();
        if (result.isSuccess) {
            setFullname(result.response.fullname);
            setNewFullname(result.response.fullname);
            setUsername(result.response.username);
        }
    };


    const fetchExchangeConnections = async () => {
        const result = await exchangesConnectionsMeGet({});

        setIsLoadingExchangeConnections(false);

        if (result.isSuccess)
            setExchangeConnections(result.response.items);
        else
            console.error('Failed to fetch exchanges:', result);
    };


    const addExchangeConnection = () => {
        var foundTypeAdd = exchangeConnections.find(exchange => exchange.type === 'add');
        if (foundTypeAdd) return;

        setIsModalVisible(true);
    };


    const deleteExchangeConnection = async (exchangeName) => {

        let dialog = confirm(t('ask-confirm-delete-exchange-connection'));

        if (!dialog) return;

        const result = await exchangesConnectionsMeDelete({ exchangeName });
        if (result.isSuccess) {
            setPopUpLevel('success');
            setPopUpText(t('success-exchange-remove'));
            setExchangeConnections(exchangeConnections.filter(exchange => exchange.name !== exchangeName));
        } else {
            setPopUpLevel('error');
            setPopUpText(t('error-exchange-remove'));
        }
    };


    const handleFullnameChange = (event) => {
        setNewFullname(event.target.value);
        $fullname.removeClass('required');
    };


    const handleToggleEditFullName = () => {
        setEditFullname(!editFullname);
    };


    const pencilSvg = <PencilSvg className='icon-svg' title={t('edit.s')} />;
    const confirmSvg = <ConfirmSvg className='icon-svg' title={t('confirm')} />;


    return (
        <Page id={pageId} >
            <MessagePopup
                duration={popUpDuration}
                level={popUpLevel}
                text={popUpText}
            />

            <ExchangeConnectionsModal
                open={isModalVisible}
                onClose={() => {
                    setIsModalVisible(false);
                    setSelectedExchange('');
                }}
                isLoading={isLoadingExchangeConnections}
                exchangesPasswordRequired={exchangesPasswordRequired}
                exchangeConnections={exchangeConnections}
                fetchExchanges={fetchExchangeConnections}
                setPopUpLevel={setPopUpLevel}
                setPopUpText={setPopUpText}
                setPopUpDuration={setPopUpDuration}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='basic'
                setIsUserAuthenticated={setIsUserAuthenticated}
                showHeader={true}
                showToolbar={true}
                dialogConfirm={false}
                maximizedScreen={false}
            >
                {isLoadingPage
                    ? <Loading id={'settings-overlay'} />
                    : (userExchanges?.length == 0 && exchangeConnections.length == 0
                        ? (
                            <Column
                                a="center"
                                fill='all'
                                j="center"
                            >
                                <Column
                                    a="center"
                                    fit='all'
                                    j="center"
                                >
                                    <img src={getImage('Wallet Centralizer')} alt="No exchanges linked" style={{ maxWidth: '80%', marginBottom: '2rem' }} />

                                    <Title variation='secondary' txt={t('First Step: ')} />
                                    <Title variation='secondary' txt={t('Connect an Exchange!')} />

                                    <Button
                                        id='add-exchange-button'
                                        onClick={addExchangeConnection}
                                    >
                                        {t('add')}
                                    </Button>
                                </Column>
                            </Column>
                        )
                        : (
                            <Column
                                a='center'
                                fill='all'
                            >
                                <Row
                                    a='start'
                                    fill='height'
                                    fit='width'
                                >
                                    <Column fit='all'>
                                        <Panel id='profile-pic'
                                            fill='height'
                                            fit='height'
                                        >
                                            <Title
                                                txt={t('profile-pic')}
                                                variation='secondary'
                                            />

                                            <hr />

                                            <Column
                                                g='0'
                                            >
                                                <ImageCropProvider>
                                                    <ImageCrop
                                                        avatar={
                                                            avatar instanceof File
                                                                ? window.URL.createObjectURL(avatar)
                                                                : avatar
                                                        }
                                                        setAvatar={setAvatar}
                                                    />
                                                </ImageCropProvider>
                                            </Column>

                                            <p style={{ width: '15rem' }}>{t('profile-pic-label')}</p>
                                        </Panel>

                                        <Panel id='personal-data'
                                            fill='height'
                                            fit='height'
                                        >
                                            <Title variation='secondary' txt={t('personal-data')} />

                                            <hr />

                                            <Column id={pageId + '-col-0-0'} fill='all'>
                                                <Column fill='width' j='between'>
                                                    <p className='label'><b>{t('fullname')}</b></p>
                                                    <Row fill='width' j='between'>
                                                        {editFullname
                                                            ? <Input
                                                                id={'user-fullname-input'}
                                                                onChange={handleFullnameChange}
                                                                placeholder={t('fullname')}
                                                                value={newFullname}
                                                                variation='text-minimal'
                                                            />
                                                            : <p id='user-fullname-label' className='fullname-text' title='-'>{fullname}</p>
                                                        }
                                                        {isPatchingUser
                                                            ? <LoadingSvg className='icon-svg spinning' style={{ padding: '.5rem' }} />
                                                            : <SwitchableIcon id='edit-fullname-button'
                                                                offImage={pencilSvg}
                                                                onImage={confirmSvg}
                                                                isOn={editFullname}
                                                                onToggle={handleToggleEditFullName}
                                                            />}
                                                    </Row>
                                                </Column>

                                                <Column fill='width'>
                                                    <p className='label'><b>{t('username.s')}</b></p>
                                                    <p className='email-text' title={username}>{username}</p>
                                                </Column>
                                            </Column>
                                        </Panel>
                                    </Column>

                                    <Column fit='all'>
                                        <Panel id='connections'
                                            fit='height'
                                        >
                                            <Row
                                                fill='width'
                                                j='between'
                                            >
                                                <Title variation='secondary' txt={t('connected-exchanges')} />

                                                <Button id='add-exchange-button'
                                                    onClick={addExchangeConnection}
                                                    state={addExchangeConnButtonState}
                                                >{t('add')}</Button>
                                            </Row>

                                            <hr />

                                            <List id='linked-exchanges'
                                                deleteExchangeConnection={deleteExchangeConnection}
                                                items={exchangeConnections}
                                                variation='user-exchanges'
                                            />
                                        </Panel>
                                    </Column>

                                    <Column style={{ width: '16.5rem' }}>
                                        <Panel id='mfa'
                                            fit='height'
                                        >
                                            <Title variation='secondary' txt={t('account-security')} />

                                            <hr />

                                            {showQrCode
                                                ? <Column>
                                                    <img
                                                        alt='Multi-Factor Authentication QR Code'
                                                        className='mfa-qr-code'
                                                        src={mfaQRCode}
                                                    />

                                                    <p>{t('mfaqrcode-label')}</p>

                                                    <Button id='add-exchange-button'
                                                        onClick={() => setShowQrCode(false)}
                                                        variation='secondary'
                                                    >{t('hide-mfa-qr-code')}</Button>
                                                </Column>
                                                : <Button id='add-exchange-button'
                                                    onClick={() => fetchMfaQrCode()}
                                                    state={showMfaQrCodeButtonState}
                                                    variation='secondary'
                                                >{t('reveal-mfa-qr-code')}</Button>
                                            }

                                            <hr />

                                            <Button id='add-exchange-button'
                                                onClick={() => {
                                                    const result = confirm(t('ask-confirm-reset-password-redirect'));
                                                    if (result)
                                                        navigate('/reset-password');
                                                }}
                                            >{t('password.change')}</Button>

                                        </Panel>
                                    </Column>
                                </Row>
                            </Column>
                        )
                    )}
            </Layout>
        </Page >
    );
};

export default Settings;
