import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// React-i18next
import { useTranslation } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';

import { getIcon, RefreshSvg, LinkSvg } from 'icons/imports'; // Helpers

import { DefaultNewsBannerPng, getImage } from 'images/imports';

import { Column, Link, List, Panel, Row, Title, SwitchableIcon } from 'components/imports';

// API Endpoints
import { newsGet } from 'apis/imports';


const ArticlesPanel = (props) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const { pageId, isUserAuthenticated } = props;

    const [news, setNews] = useState([]);
    const [loadingNews, setLoadingNews] = useState(true);

    const linkIcon = <LinkSvg className='icon-svg' />;

    const refreshIcon = <RefreshSvg className={'icon-svg refresh' + (loadingNews ? ' spinning' : '')} />;


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            getNews();
        }
    }, [location.pathname]);


    const getNews = async () => {

        setLoadingNews(true);
        const result = await newsGet({ limit: 10 });
        setLoadingNews(false);

        if (result.isSuccess) {

            let items = result.response.items.map(item => {

                if (!item.banner) item.banner = DefaultNewsBannerPng;

                item.timestamp = item.publishedAt * 1000;

                item.sourceIcon = getIcon(item.source);
                item.sourceImage = getImage(item.source);

                item.authors = item.authors.join(' • ');
                item.authorsMin = item.authors.length >= 30 ? item.authors.substring(0, 27) + '...' : item.authors;
                item.authors = 'By ' + item.authors;

                item.id = `article_${item.publishedAt}_${item.api}_${item.source}`;

                return item;
            });

            setNews(items);
        }
    };


    return (
        <Panel id='last-news'
            fill='all'
        >
            <Row
                fill='width'
                j='between'
            >
                <Title variation='tertiary' txt={t('last-news')} />

                <Link
                    id='go-to-news-page'
                    onClick={() => {
                        let result = confirm(t('confirm-redirect-to-news'));

                        if (!result) { return; }
                        navigate('/news');
                    }}
                    variation='primary redirect'
                >
                    <p>{t('see-more-news')}</p>&nbsp;&nbsp;

                    <span>{linkIcon}</span>
                </Link>

                <SwitchableIcon
                    id='refresh-trades'
                    onToggle={getNews}
                    staticImage={refreshIcon}
                />
            </Row>

            {loadingNews || !isUserAuthenticated ? (
                <div id='last-articles-skeleton'>
                    {[0, 1].map(() => (<>
                        <hr />

                        <Column p='pri'>
                            <Skeleton
                                height='5rem'
                                width='100%'
                            />

                            <Row>
                                <Skeleton
                                    height='1.5rem'
                                    width='1.5rem'
                                />

                                <Skeleton
                                    height='1.25rem'
                                    width='5rem'
                                />
                            </Row>

                            <Column g='0'>
                                <Skeleton
                                    height='1.25rem'
                                    width='100%'
                                />

                                <Skeleton
                                    height='1.25rem'
                                    width='10rem'
                                />
                            </Column>
                        </Column>
                    </>))}
                </div>
            ) : (
                <List id='news-list'
                    items={news}
                    lang={props.appLang}
                    variation='news-articles'
                />
            )}
        </Panel >
    );
};


ArticlesPanel.propTypes = {
    appLang: PropTypes.string,
    isUserAuthenticated: PropTypes.bool,
    pageId: PropTypes.string
};

export default ArticlesPanel;