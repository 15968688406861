
import { healthzGet } from 'apis/endpoints/healthzGet';


const checkHealth = async () => {
    const result = await healthzGet();
    return result;
};


export { checkHealth };
