
import { getGlobalAssetsQuotes } from './assets';

// Nota: caso houver slugs com um mesmo symbol, assume-se que serão em corretoras diferentes,
// então se caso for necessário, informe o exchange_slug e filtre as exceções
export const getAssetQuote = ({symbol=null, name=null, slug=null}) => {
    const quotes = getGlobalAssetsQuotes({});
    let quote = { symbol, name, slug };

    if (quotes) {
        if (symbol) {
            symbol = symbol.toUpperCase();
            quote = quotes.filter(quote => quote.symbol.toUpperCase() === symbol);
        }
        else if (name) {
            name = name.toUpperCase();
            quote = quotes.filter(quote => quote.name.toUpperCase() === name);
        }
        else if (slug) {
            slug = slug.toUpperCase();
            quote = quotes.filter(quote => quote.slug.toUpperCase() === slug);
        }
    }
    
    if (quote.length > 0) return quote[0];
    
    return symbol;
};