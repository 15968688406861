/* eslint-disable @typescript-eslint/no-unused-vars */

/* PNG Images */
import BenzingaPng from 'images/png/benzinga-logo.png';
import BitcoinistPng from 'images/png/bitcoinist-logo.png';
import BiztocPng from 'images/png/biztoc-logo.png';
import BusinessInsiderPng from 'images/png/business-insider-logo.png';
import BusinessStandardPng from 'images/png/business-standard-logo.png';

import CnbcPng from 'images/png/cnbc-logo.png';
import CnetPng from 'images/png/cnet-logo.png';
import CnnPng from 'images/png/cnn-logo.png';
import CointelegraphPng from 'images/png/cointelegraph-logo.png';
import CryptoSlatePng from 'images/png/cryptoslate-logo.png';

import DecryptPng from 'images/png/decrypt-logo.png';

import FinancialExpressPng from 'images/png/financial-express-logo.png';
import FinancialNewsLondonPng from 'images/png/financial-news-london-logo.png';
import ForbesPng from 'images/png/forbes-logo.png';
import FoxBusinessNewsPng from 'images/png/fox-business-news-logo.png';

import GlobeNewswirePng from 'images/png/globenewswire-logo.png';

import InvestorsBusinessDailyPng from 'images/png/investors-business-daily-logo.png';
import InvestorsObserverPng from 'images/png/investors-observer-logo.png';
import InvestorIdeasPng from 'images/png/investor-ideas-logo.png';

import KiplingerPng from 'images/png/kiplinger-logo.png';

import MarketWatchPng from 'images/png/marketwatch-logo.png';
import MoneyControlPng from 'images/png/money-control-logo.png';
import MoneyMorningPng from 'images/png/money-morning-logo.png';

import NewsBTCPng from 'images/png/news-btc-logo.png';

import PrNewswirePng from 'images/png/pr-newswire-logo.png';
import PymntsPng from 'images/png/pymnts-logo.png';

import SouthChinaMorningPostPng from 'images/png/south-china-morning-post-logo.png';

import TechwebPng from 'images/png/techweb-logo.png';
import TheDailyHodlPng from 'images/png/the-daily-hodl-logo.png';
import TheAtlanticPng from 'images/png/the-atlantic-logo.png';
import TheMotleyFoolPng from 'images/png/the-motley-fool-logo.png';
import TheWeekNewsPng from 'images/png/the-week-news-logo.png';

import ZacksPng from 'images/png/zacks-logo.png';

import AppStoreButtonPng from 'images/png/app-store-button.png';
import DefaultNewsBannerPng from 'images/png/default-news-banner.png';
import PlayStoreBannerPng from 'images/png/play-store-banner.png';
import SmartPhonePng from 'images/png/smart-phone.png';
import TabletPng from 'images/png/tablet.png';

import SmartTradeLogoPng from 'images/png/smart-trade-logo.png';

import WalletCentralizerPng from 'images/png/wallet-centralizer.png';
import ConnectExchangesPng from 'images/png/connect-exchanges.png';
import WalletPng from 'images/png/wallet.png';
import PieChartSkeletonPng from 'images/png/pie-chart.png';
import OrdersHistoryPng from 'images/png/orders-history.png';

export { AppStoreButtonPng, DefaultNewsBannerPng, PlayStoreBannerPng, SmartPhonePng, SmartTradeLogoPng, TabletPng, WalletCentralizerPng, ConnectExchangesPng, WalletPng, PieChartSkeletonPng, OrdersHistoryPng };


/* SVG Images */
import BnxLogoSvg from 'images/svg/bnx-logo.svg';
import BgAuthentication from 'images/svg/bg-authentication.svg';
import ExchangeConnectionSvg from 'images/svg/exchange-connection.svg';
import SmartTradeLogoSvg from 'images/svg/smart-trade-logo.svg';
import SmartTradeLogoByBnxSvg from 'images/svg/smart-trade-logo-by-bnx.svg';

export { BnxLogoSvg, BgAuthentication, ExchangeConnectionSvg, SmartTradeLogoSvg, SmartTradeLogoByBnxSvg };


function getImage(name, type = 'PNG') {

    switch (type.toLowerCase()) {
        case 'png':
            switch (name) {
                case 'Benzinga':
                    return BenzingaPng;
                case 'Bitcoinist':
                    return BitcoinistPng;
                case 'Biztoc.com':
                    return BiztocPng;
                case 'Business Insider':
                    return BusinessInsiderPng;
                case 'Business Standard':
                    return BusinessStandardPng;
                case 'CNBC':
                    return CnbcPng;
                case 'CNET':
                    return CnetPng;
                case 'CNN':
                    return CnnPng;
                case 'Cointelegraph':
                    return CointelegraphPng;
                case 'CryptoSlate':
                    return CryptoSlatePng;
                case 'Decrypt':
                    return DecryptPng;
                case 'Decrypt.co':
                    return DecryptPng;
                case 'Financial News London':
                    return FinancialNewsLondonPng;
                case 'Forbes':
                    return ForbesPng;
                case 'Fox Business News':
                    return FoxBusinessNewsPng;
                case 'GlobeNewswire':
                    return GlobeNewswirePng;
                case 'Investors Business Daily':
                    return InvestorsBusinessDailyPng;
                case 'InvestorsObserver':
                    return InvestorsObserverPng;
                case 'Investor Ideas':
                    return InvestorIdeasPng;
                case 'Kiplinger':
                    return KiplingerPng;
                case 'MarketWatch':
                    return MarketWatchPng;
                case 'Money Control':
                    return MoneyControlPng;
                case 'Money Morning':
                    return MoneyMorningPng;
                case 'Motley Fool':
                    return TheMotleyFoolPng;
                case 'newsBTC':
                    return NewsBTCPng;
                case 'PR Newswire':
                    return PrNewswirePng;
                case 'pymnts.com':
                    return PymntsPng;
                case 'South China Morning Post':
                    return SouthChinaMorningPostPng;
                case 'Techweb.com.cn':
                    return TechwebPng;
                case 'The Atlantic':
                    return TheAtlanticPng;
                case 'The Daily Hodl':
                    return TheDailyHodlPng;
                case 'The Financial Express':
                    return FinancialExpressPng;
                case 'The Week News':
                    return TheWeekNewsPng;
                case 'Zacks Commentary':
                    return ZacksPng;
                case 'Wallet Centralizer':
                    return WalletCentralizerPng;
                case 'Connect Exchanges':
                    return ConnectExchangesPng;
                case 'Wallet':
                    return WalletPng;
                case 'Pie Chart Skeleton':
                    return PieChartSkeletonPng;
                case 'Orders History':
                    return OrdersHistoryPng;
                default:
                    return null;
            }
        default:
            return name;
    }
}

export { getImage };