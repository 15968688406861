import React, { useState, useEffect } from 'react';
import $ from 'jquery';

import { BrowserView, MobileView, isBrowser } from 'react-device-detect';

import CryptoOverview from 'components/utils/cards/cryptoOverview/CryptoOverview';

import './CardsCarousel.css';

// eslint-disable-next-line react/prop-types
function CardsCarousel({ cardsList }) {

    //const slideTime = 7 * 1000;  // seconds in milliseconds

    const [clicksCount, setClicksCount] = useState(0);

    let slideIndex = 0;
    let isSlideEnabled = false;

    useEffect(() => {

        var carouselNextButton = $('#carousel-next')[0],
            carouselPrevButton = $('#carousel-prev')[0];

        carouselNextButton.addEventListener('click', () => {
            if(isSlideEnabled){
                slideIndex++;
                var maxSlideIndex = checkCarouselIndex(carouselPrevButton, carouselNextButton);
                updateWrapper(isSlideEnabled);
                updateCarouselSlidesMap(maxSlideIndex, carouselNextButton, carouselPrevButton);
                
                hangleToggleClicksCount();
            }
        });
        
        carouselPrevButton.addEventListener('click', () => {
            if(isSlideEnabled){
                slideIndex--;
                var maxSlideIndex = checkCarouselIndex(carouselPrevButton, carouselNextButton);
                updateWrapper(isSlideEnabled);
                updateCarouselSlidesMap(maxSlideIndex, carouselNextButton, carouselPrevButton);
    
                hangleToggleClicksCount();
            }
        });

    }, [cardsList]);

    useEffect(() => {

        var carouselNextButton = $('#carousel-next')[0],
            carouselPrevButton = $('#carousel-prev')[0];

        window.onresize = onResize(carouselPrevButton, carouselNextButton);

        var maxSlideIndex = checkCarouselIndex(carouselPrevButton, carouselNextButton);

        updateCarouselSlidesMap(maxSlideIndex, carouselNextButton, carouselPrevButton);
        
        var intervalId = setInterval(() => {

            if (isBrowser) {
                slideIndex++;
                var maxSlideIndex = checkCarouselIndex(carouselPrevButton, carouselNextButton);
                updateWrapper(isSlideEnabled);
                updateCarouselSlidesMap(maxSlideIndex, carouselNextButton, carouselPrevButton);
            }
        }, 5000);

        return () => clearInterval(intervalId);

    }, [cardsList]);

    function updateCarouselSlidesMap(maxSlideIndex, carouselNextButton, carouselPrevButton) {

        // if(slideIndex == 0){ carouselPrevButton.classList.add('hidden'); } else { carouselPrevButton.classList.remove('hidden'); }
        // if(slideIndex > maxSlideIndex){ carouselNextButton.classList.add('hidden'); } else { carouselNextButton.classList.remove('hidden'); }

        var carouselSlides = $('.carousel-slides')[0];

        // remove all carouselSlides children
        while (carouselSlides.firstChild) carouselSlides.removeChild(carouselSlides.firstChild);

        function handleSlideClick(index) {
            slideIndex = index;
            checkCarouselIndex(carouselPrevButton, carouselNextButton);
            updateWrapper(isSlideEnabled);

            $('.carousel-slides span').removeClass('active');
            carouselSlides.childNodes[index].classList.add('active');

            hangleToggleClicksCount();
        }

        for (let i = 0; i < maxSlideIndex; i++) {
            let span = document.createElement('span');
            if (i === slideIndex) {
                span.classList.add('active');
            }
            span.addEventListener('click', handleSlideClick.bind(null, i));
            carouselSlides.appendChild(span);
        }
    }

    function hangleToggleClicksCount() {
        setClicksCount(clicksCount + 1);
    }

    function onResize(carouselPrevButton, carouselNextButton) {
        checkCarouselIndex(carouselPrevButton, carouselNextButton);
        updateWrapper(isSlideEnabled);
    }

    function checkCarouselIndex(carouselPrevButton, carouselNextButton) {

        var slides = $('.carousel-slide'),
            wrapperWidth = $('.carousel-wrapper')[0].clientWidth,
            cardWidth = $('#crypto-card-0')[0].clientWidth,
            cardsCount = slides.length;

        var ableSlides = wrapperWidth - (cardWidth * cardsCount) < 0,
            maxSlideIndex = cardsCount <= 4 ? 1 : Math.ceil((cardsCount / 4));

        if (ableSlides) {
            carouselNextButton.classList.remove('disabled');
            carouselPrevButton.classList.remove('disabled');
            isSlideEnabled = true;

            if (slideIndex === maxSlideIndex) {
                slideIndex = 0;
            }
            else if (slideIndex < 0) {
                slideIndex = maxSlideIndex - 1;
            }
        }
        else {
            carouselPrevButton.classList.add('disabled');
            carouselNextButton.classList.add('disabled');
            isSlideEnabled = false;
        }

        return maxSlideIndex;
    }

    function updateWrapper(isSlideEnabled) {
        var wrapper = $('.carousel-wrapper')[0],
            slides = $('.carousel-slide');
        if (isSlideEnabled) {
            wrapper.style.transform = `translateX(-${slides.length <= 4 ? 0 : (slideIndex * 4) * slides[0].clientWidth}px)`;
        }
    }

    return (
        <div className="carousel-horizontal">
            <BrowserView>
                <div className='carousel-slides'>
                </div>
                <div className="carousel-wrapper">
                    {
                        // eslint-disable-next-line react/prop-types
                        cardsList.map((cardData, index) => {
                            return (<CryptoOverview key={index} id={'crypto-card-' + index} data={cardData} isInCarousel={true} />);
                        })
                    }
                </div>
                <div className="carousel-navigation">
                    <div id='carousel-prev' className="carousel-button carousel-button disabled">&lt;</div>
                    <div id='carousel-next' className="carousel-button carousel-button disabled">&gt;</div>
                </div>
            </BrowserView>
            <MobileView>
                <div className='carousel-slides' style={{'display': 'none'}}>
                </div>
                <div className="carousel-wrapper">
                    {
                        // eslint-disable-next-line react/prop-types
                        cardsList.map((cardData, index) => {
                            return (<CryptoOverview key={index} id={'crypto-card-' + index} data={cardData} isInCarousel={true} />);
                        })
                    }
                </div>
                <div className="carousel-navigation" style={{'display': 'none'}}>
                    <div id='carousel-prev' className="carousel-button carousel-button disabled">&lt;</div>
                    <div id='carousel-next' className="carousel-button carousel-button disabled">&gt;</div>
                </div>
            </MobileView>
        </div>
    );
}

export default CardsCarousel;
