import $ from 'jquery';

import { appEnv, userService as users } from 'constants';
import { getAuthorization } from 'utils/cookies';

const ordersMeGet = async ({ exchanges, pairs, side, since, until, limit = 50 }) => {

    const method = 'get';
    const { hosts, version } = users;

    const host = hosts[appEnv];

    let url = `${host}/api/${version}/orders/me`;

    if (limit)
        url += `?limit=${encodeURIComponent(limit)}`;

    if (pairs && pairs.length > 0)
        url += `&pairs=${encodeURIComponent(pairs)}`;

    if (exchanges && exchanges.length > 0)
        url += `&exchange_slugs=${encodeURIComponent(exchanges)}`;

    if (side)
        url += `&side=${encodeURIComponent(side)}`;

    if (since)
        url += `&since=${since}`;

    if (until)
        url += `&until=${until}`;

    const auth = await getAuthorization();

    var settings = {
        url: url,
        method: method,
        timeout: 0,
        processData: false,
        mimeType: 'multipart/form-data',
        contentType: false,
        beforeSend: (xhr) => xhr.setRequestHeader('Authorization', auth)
    };

    try {
        const response = await $.ajax(settings);
        const parsedResponse = typeof response === 'string' ? JSON.parse(response) : response;

        return { isSuccess: true, response: parsedResponse };

    } catch (error) {
        let status = 0;
        let errorType = '';

        if (error && error.status) {
            status = error.status;
            try {
                const response = JSON.parse(error.responseText);
                if ('detail' in response && 'type' in response.detail)
                    errorType = response.detail.type;
            } catch (e) { errorType = 'InternalServerError'; }
        }

        return {
            isSuccess: false,
            errorType,
            status,
        };
    }
};

export { ordersMeGet };
