/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';

import { useTranslation } from 'react-i18next';

import { Badge, Card, Column, Date, ExchangesPill, Row, Label, SwitchableIcon } from 'components/imports';

import { AtsSvg, DeleteSvg, buildExchangesStorageUrl, InfoSvg } from 'icons/imports'; // SVG

// Utils
import { currencyFormatter, percentageFormatter } from 'utils/formatters';
import { getSelectedCurrency } from 'utils/cookies';
import { getUserConnectedExchanges } from 'utils/exchanges';

import { smallBalance } from 'constants';

import './List.css';


const List = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [userExchanges,] = useState(getUserConnectedExchanges({}));
    const [currency,] = useState(getSelectedCurrency());

    const className = props.className ?? '';

    const deleteIcon = <DeleteSvg className='icon-svg no' />;
    const atsSvg = <AtsSvg className='icon-svg' title={t('generated-by-ats')} />;
    const infoSvg = <InfoSvg className={'icon-svg info'} />;

    const handleExpandCoinListItem = (symbol) => {

        $('.list.coin-list .list-item:not(.' + symbol + ') .expandable').addClass('d-none');

        let $expandable = $('.list.coin-list .list-item.' + symbol + ' .expandable');

        $expandable.toggleClass('d-none');
    };


    const redirectToTrends = (id) => {
        localStorage.setItem('article', id);

        let result = confirm(t('ask-confirm-news-redirect'));

        if (!result) { return; }

        navigate('/news');
    };


    switch (props.variation) {

        case 'coin-ranking':

            return (
                <Column
                    className={'list coin-list ' + (props.variation + ' ' + className).trim()}
                    id={props.id}
                    fill='width'
                    key={'list-coin-ranking-' + props.id}
                    scroll
                >
                    {
                        props.items.map((item, index) => {

                            var { change, pnl, price, symbol } = item;

                            var trend = pnl > 0 ? 'up' : 'down';

                            price = currencyFormatter(price);

                            return (
                                <Row className='list-item' key={symbol} fill='width'>

                                    <Column fill='height'>
                                        <p className='rank-position' id={`rank-position-${index}`}>
                                            <b>{index + 1}º</b>
                                        </p>
                                    </Column>

                                    <Row
                                        className='coin-label'
                                        fill='width'
                                        j='between'

                                    >
                                        <Label
                                            value={{ symbol }}
                                            variation='symbol-and-name'
                                        />

                                        <Column
                                            a='end'
                                            fill='width'
                                            j='between'

                                        >
                                            <p className='currency'><b>{price}</b></p>

                                            <Row a='center'>
                                                <p className={`pnl trend-${pnl > 0 ? 'up' : 'down'}-text`}>
                                                    <b>{percentageFormatter({ v: pnl })}</b>
                                                </p>
                                                <p className={`currency trend-${trend}-text`}>(${change})</p>
                                            </Row>
                                        </Column>
                                    </Row>
                                </Row>
                            );
                        })
                    }
                </Column >
            );

        case 'coin-variation':

            return (
                <Column
                    className={'list coin-list ' + props.variation}
                    fill='all'
                    id={props.id}
                    j='between'
                    key={'list-coin-variation-' + props.id}
                >
                    {
                        props.items.map((item) => {

                            var { pnl, price, symbol } = item;

                            pnl /= 100;
                            price = currencyFormatter(price);

                            return (
                                <Row
                                    fill='width'
                                    key={symbol + '/USDT'}
                                    j='between'
                                >
                                    <Label
                                        value={{ symbol }}
                                        variation='symbol-and-name'
                                    />
                                    <Column a='end' g='0'>
                                        <p className={`pnl trend-${pnl > 0 ? 'up' : 'down'}-text`}>
                                            <b>{percentageFormatter({ v: pnl })}</b>
                                        </p>

                                        <Row>
                                            <p className='currency money-value'>{price}</p>
                                            <p className="money-currency">USDT</p>
                                        </Row>
                                    </Column>
                                </Row>
                            );
                        })
                    }
                </Column>
            );

        case 'news-articles':

            return (
                <Column
                    className='list scroll'
                    fill='width'
                    g='0'
                    key={'list-news-articles-' + props.id}
                    id={props.id}
                >
                    {
                        props.items.length > 0 ?
                            props.items.map((item, index) => {
                                let { fromNow, source, sourceIcon, timestamp, title, url, banner } = item;

                                return (
                                    <Column
                                        fill='width'
                                        g='0'
                                        id={'article-' + index}
                                        key={'article-' + index}
                                    >
                                        <hr />

                                        <Card
                                            banner={banner}
                                            date={timestamp}
                                            fromNow={fromNow}
                                            id={'article-card-' + index}
                                            key={index}
                                            lang={props.lang}
                                            onClick={() => props.onItemClick ? props.onItemClick(item.id) : redirectToTrends(item.id)}
                                            showAllTitle={true}
                                            source={source}
                                            sourceIcon={sourceIcon}
                                            title={title}
                                            url={url}
                                            variation='article-preview'
                                        />
                                    </Column>
                                );
                            }) :
                            <Row className='list-message' fill='width' j='center'>
                                <b>{t('no-results')}</b>
                            </Row>
                    }
                </Column>
            );

        case 'orders':

            return (
                <Column
                    className='list orders'
                    fill='width'
                    scroll
                    id={props.id}
                    key={'list-orders-' + props.id}
                >
                    {
                        props.items.length > 0 ?
                            <>
                                <Row id='orders-list-header'
                                    fill='width'
                                    j='between'
                                >
                                    {
                                        [
                                            'ATS', 'time', 'exchange.s', 'pair.s',
                                            'price.s', 'side.s', 'status', 'filled'
                                        ].map((i18nKey, index) => (
                                            <label className={'col-' + index} key={i18nKey + '-label'}>
                                                <b>{t(i18nKey)}</b>
                                            </label>
                                        ))
                                    }
                                </Row>

                                <Column fill='width'>
                                    {
                                        props.items.map((order, index) => {

                                            let { isAtsOrder, status, exchangeSlug, pair, price, side, timestamp, amount, filled } = order;

                                            const exchange = props.exchanges.find(exchange => exchange.slug === exchangeSlug);

                                            return (
                                                <Row
                                                    className='list-item'
                                                    fill='width'
                                                    j='between'
                                                    key={'signal-' + index}
                                                >
                                                    <div className='col-0'>{isAtsOrder && atsSvg}</div>

                                                    <Date className='col-1' timestamp={timestamp} />

                                                    {exchange ?
                                                        <Label
                                                            className='col-2'
                                                            value={{ exchange }}
                                                            variation='exchange'
                                                        />
                                                        : <p>{exchangeSlug}</p>
                                                    }

                                                    <Label
                                                        className='col-3'
                                                        value={{ pair }}
                                                        variation='pair-and-name'
                                                    />

                                                    <p className='col-4'>
                                                        <b>{currencyFormatter(price)}</b>
                                                    </p>

                                                    <Badge
                                                        className='col-5'
                                                        variation={side}
                                                    >
                                                        {side.toUpperCase()}
                                                    </Badge>

                                                    <div className='col-6'>
                                                        <p>{t(status)}</p>
                                                    </div>

                                                    <div className='col-7'>
                                                        <Row
                                                            fill='width'
                                                            j='end'
                                                            g='0'
                                                        >
                                                            <p>{filled}</p>

                                                            <Tooltip
                                                                placement="top"
                                                                title={
                                                                    t('filled-of-amount')
                                                                        .replace('{x}', filled)
                                                                        .replace('{y}', amount)
                                                                }
                                                                align={'center'}
                                                            >
                                                                {infoSvg}
                                                            </Tooltip>
                                                        </Row>
                                                    </div>
                                                </Row>
                                            );
                                        })
                                    }
                                </Column>
                            </>
                            : <Row fill='width' j='center'><b>{t('no-results')}</b></Row>
                    }
                </Column>
            );

        case 'positions':

            return (
                <Column id={props.id}
                    className={'list coin-list ' + props.variation}
                    scroll
                    fill='width'
                    key={'list-positions-' + props.id}
                >
                    <Column
                        fill='width'
                        g='0'
                    >
                        {
                            props.items.map((item) => {

                                var { balance, maxPnl, perExchange, symbol, total } = item;

                                var pnl = maxPnl;

                                if (props.hideSmallPositions && balance < smallBalance)
                                    return <></>;

                                return (
                                    <Column
                                        className={'list-item ' + symbol}
                                        fill='width'
                                        g='0'
                                        key={symbol}
                                        onClick={() => handleExpandCoinListItem(symbol)}
                                    >
                                        <hr />

                                        <Column
                                            className='card'
                                            fill='width'
                                        >
                                            <Row
                                                a='center'
                                                fill='width'
                                                j='between'
                                            >
                                                <Label
                                                    value={{ symbol }}
                                                    variation='symbol-and-name'
                                                />

                                                <ExchangesPill exchanges={Object.keys(perExchange)} />
                                            </Row>

                                            <Row
                                                fill='width'
                                                j='between'
                                            >
                                                <p>{t('total')}</p>

                                                <Column
                                                    a='end'
                                                    fit='width'
                                                    g='0'
                                                >
                                                    {
                                                        symbol !== currency
                                                            ?
                                                            <p className={'amount fill-width ' + (props.hideValues ? 'occult' : '')}>
                                                                <b>{currencyFormatter(total || 0)}</b>
                                                            </p>
                                                            : <></>
                                                    }

                                                    <Row>
                                                        <p className={'currency money-value ' + (props.hideValues ? ' occult' : '')}>
                                                            {currencyFormatter(balance || 0)}
                                                        </p>

                                                        <p className='money-currency'>
                                                            {currency}
                                                        </p>
                                                    </Row>

                                                    {pnl != undefined && pnl != null
                                                        ? <p className={'trend-' + (pnl >= 0 ? 'up' : 'down') + '-text'}>
                                                            <b>{percentageFormatter({ v: pnl, multiply: false })}</b>
                                                        </p>
                                                        : <></>
                                                    }
                                                </Column>
                                            </Row>
                                        </Column>

                                        <Column className='expandable d-none' g='0'>
                                            {
                                                Object.entries(perExchange).map(([exchange, values]) => {

                                                    let { balance, pnl, total } = values;

                                                    return (
                                                        <Row
                                                            a='center'
                                                            fill='width'
                                                            j='between'
                                                            key={exchange}
                                                            p='pri'
                                                        >
                                                            <img
                                                                alt={exchange + ' logo'}
                                                                className='asset-icon'
                                                                src={buildExchangesStorageUrl(exchange)}
                                                                title={exchange}
                                                            />

                                                            <Column
                                                                a='end'
                                                                fill='width'
                                                                g='0'
                                                            >
                                                                {
                                                                    symbol !== currency
                                                                        ? <p className={'amount ' + (props.hideValues ? 'occult' : '')}>
                                                                            <b>{total}</b>
                                                                        </p>
                                                                        : <></>
                                                                }

                                                                <Row>
                                                                    {
                                                                        balance
                                                                            ? <p className={'currency money-value ' + (props.hideValues ? ' occult' : '')}>
                                                                                {currencyFormatter(balance)}
                                                                            </p>
                                                                            : <p>--</p>
                                                                    }

                                                                    <p className='money-currency'>
                                                                        {currency}
                                                                    </p>
                                                                </Row>

                                                                {pnl != undefined && pnl != null
                                                                    ? <p className={'trend-' + (pnl >= 0 ? 'up' : 'down') + '-text'}>
                                                                        {percentageFormatter({ v: pnl, multiply: false })}
                                                                    </p>
                                                                    : <></>
                                                                }
                                                            </Column>
                                                        </Row>
                                                    );
                                                })
                                            }
                                        </Column>
                                    </Column>
                                );
                            })
                        }
                    </Column>
                </Column>
            );

        case 'signals':

            return (
                <Column
                    className='list signals scroll'
                    fill='width'
                    id={props.id}
                    key={'list-signals-' + props.id}
                >
                    {
                        props.items.length > 0 ?
                            <>
                                <Row id='signals-list-header'
                                    fill='width'
                                    j='between'
                                >
                                    <label className='col-0'><b>{t('time')}</b></label>
                                    <label className='col-1'><b>{t('exchange.s')}</b></label>
                                    <label className='col-2'><b>{t('pair.s')}</b></label>
                                    <label className='col-3' ><b>{t('price.s')}</b></label>
                                    <label className='col-4' ><b>{t('side.s')}</b></label>
                                    <label className='col-5' ><b>{t('timeframe.s')}</b></label>
                                    <label className='col-6' ><b>{t('pnl')}</b></label>
                                </Row>

                                <Column fill='width'>
                                    {
                                        props.items.map((signal, index) => {

                                            let { exchangeSlug, pnl, pair, price, side, timeframe, timestamp } = signal;

                                            const exchange = props.exchanges.find(exchange => exchange.slug === exchangeSlug);

                                            return (
                                                <Row
                                                    className='list-item'
                                                    fill='width'
                                                    j='between'
                                                    key={'signal-' + index}
                                                >
                                                    <Date className='col-0' timestamp={timestamp} />

                                                    {exchange ?
                                                        <Label
                                                            className='col-1'
                                                            value={{ exchange }}
                                                            variation='exchange'
                                                        />
                                                        : <p>{exchangeSlug}</p>
                                                    }

                                                    <Label
                                                        className='col-2'
                                                        value={{ pair }}
                                                        variation='pair-and-name'
                                                    />

                                                    <p className='col-3'>
                                                        <b>{currencyFormatter(price)}</b>
                                                    </p>

                                                    <Badge
                                                        className='col-4'
                                                        variation={side}
                                                    >
                                                        {side.toUpperCase()}
                                                    </Badge>

                                                    <p className='col-5'>
                                                        <b>{timeframe}</b>
                                                    </p>

                                                    <p className={'col-6 pnl trend-' + (pnl ? (pnl > 0 ? 'up' : 'down') : 'neutral') + '-text'}>
                                                        <b>{pnl != undefined && pnl != null ? percentageFormatter({ v: pnl }) : '-'}</b>
                                                    </p>
                                                </Row>
                                            );
                                        })
                                    }
                                </Column>
                            </>
                            : <Row fill='width' j='center'><b>{t('no-results')}</b></Row>
                    }
                </Column>
            );

        case 'trend-score':

            return (
                <Column
                    className={'list ' + props.variation}
                    fill='all'
                    id={props.id}
                    j='between'
                    key={'list-trend-score-' + props.id}
                >
                    {
                        props.items.map((item) => {

                            var { pnl, price, symbol, score } = item;

                            pnl /= 100;
                            price = currencyFormatter(price);

                            return (
                                <Row
                                    fill='width'
                                    key={symbol + '/' + props.selectedCurrency}
                                    j='between'
                                >
                                    <Label
                                        value={{ symbol }}
                                        variation='symbol-and-name'
                                    />

                                    <Row>
                                        <Column a='end' g='0'>
                                            <p className={`pnl trend-${pnl > 0 ? 'up' : 'down'}-text`}>
                                                <b>{percentageFormatter({ v: pnl })}</b>
                                            </p>

                                            <Row>
                                                <p className='currency money-value'>{price}</p>
                                                <p className="money-currency">{props.selectedCurrency}</p>
                                            </Row>
                                        </Column>


                                        <Badge
                                            id={symbol.toLowerCase() + '-trend-score'}
                                            variation='primary'
                                        >
                                            {Math.round(score)}
                                        </Badge>
                                    </Row>
                                </Row>
                            );
                        })
                    }
                </Column>
            );

        case 'user-exchanges':

            return (
                <Column id={props.id}
                    className='list user-exchanges'
                    fill='width'
                    key={'list-user-exchanges-' + props.id}
                    scroll
                >
                    {
                        props.items.length > 0 ?
                            props.items.map((item, index) => {

                                let { slug, credentials } = item;
                                let { apiKey, secret, password } = credentials;

                                return (
                                    <Column
                                        className='item added'
                                        fill='width'
                                        j='between'
                                        key={'added-exchange-' + index}
                                        p='pri'
                                    >
                                        <Row
                                            fill='width'
                                            j='between'
                                        >
                                            <Label
                                                variation='exchange'
                                                value={{ exchange: userExchanges.find(exchange => exchange.slug == slug) }}
                                            />

                                            <SwitchableIcon
                                                id='close-add-exchange-icon'
                                                onToggle={() => props.deleteExchangeConnection(slug)}
                                                staticImage={deleteIcon}
                                                title='Delete connection'
                                            />
                                        </Row>

                                        <Row
                                            fit='all'
                                            g='2'
                                            j='between'
                                        >
                                            <Column
                                                fit='all'
                                                g='0'
                                            >
                                                <label><b>{t('api-key')}</b></label>
                                                <label>{apiKey}</label>
                                            </Column>

                                            <Column
                                                fit='all'
                                                g='0'
                                            >
                                                <label><b>{t('secret.s')}</b></label>
                                                <label>{secret}</label>
                                            </Column>

                                            {password
                                                ?
                                                <Column
                                                    fit='all'
                                                    g='0'
                                                >
                                                    <label><b>{t('password.s')}</b></label>
                                                    <label>{password}</label>
                                                </Column>
                                                : <></>
                                            }
                                        </Row>
                                    </Column>
                                );

                            }) : <Row className='list-message' fill='width' j='center'>
                                {t('no-exchanges-connected')}
                            </Row>
                    }
                </Column>
            );

        default:
    }
};


List.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    deleteExchangeConnection: PropTypes.func,
    exchanges: PropTypes.array,
    hideSmallPositions: PropTypes.bool,
    hideValues: PropTypes.bool,
    id: PropTypes.string,
    items: PropTypes.array,
    lang: PropTypes.string,
    selectedCurrency: PropTypes.string,
    selectedTimeframe: PropTypes.string,
    onChange: PropTypes.func,
    onItemClick: PropTypes.func,
    variation: PropTypes.string
};


export default List;
