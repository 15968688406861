/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Ant Design Components
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

// Custom Components
import { Button, Column, Input, Row, Title } from 'components/imports';
import { Select } from 'antd';

import { exchangesConnectionsMePost } from 'apis/imports';

// Constants
import { getGlobalExchanges } from 'utils/exchanges';

const ExchangeConnectionsModal = ({ open, onClose, exchangesPasswordRequired, isLoading, exchangeConnections, fetchExchanges, setPopUpLevel, setPopUpText, setPopUpDuration }) => {

    const { t } = useTranslation();

    const [exchanges,] = useState(getGlobalExchanges({}));
    const [availableExchangesList, setAvailableExchangesList] = useState([]);

    const [selectedExchange, setSelectedExchange] = useState('');
    const [apiKey, setApiKey] = useState();
    const [secret, setSecret] = useState();
    const [password, setPassword] = useState();

    const [confirmButtonState, setConfirmButtonState] = useState('disabled');


    useEffect(() => {
        if (isLoading) return;

        const connectedExchanges = exchangeConnections.map(exchange => exchange.slug);
        let exchangesList = Object.values(exchanges).reduce((acc, exchange) => {
            
            if (!connectedExchanges.includes(exchange.slug)
                && exchange.slug !== selectedExchange)
                acc.push(exchange);

            return acc;
        }, []);
        setAvailableExchangesList(exchangesList);
        console.log(exchangesList);
    }, [exchangeConnections]);


    useEffect(() => setSelectedExchange(availableExchangesList[0]),
        [availableExchangesList]);


    useEffect(() => {

        let hasApiKey = apiKey && apiKey.length > 0;
        let hasSecret = secret && secret.length > 0;
        let hasPassword = true;

        if (exchangesPasswordRequired.includes(selectedExchange))
            hasPassword = password && password.length > 0;

        if (hasApiKey && hasSecret && hasPassword)
            setConfirmButtonState('enabled');
        else
            setConfirmButtonState('disabled');

    }, [apiKey, password, secret]);


    const changeSelectedExchange = (newSelectedExchange = selectedExchange) => {
        setSelectedExchange(newSelectedExchange);
    };


    const clearForm = () => {
        setConfirmButtonState('disabled');
        setApiKey('');
        setSecret('');
        setPassword('');
    };


    const handleConfirm = async (event) => {
        event.preventDefault();

        setConfirmButtonState('loading');

        try {
            const props = {
                exchangeSlug: selectedExchange,
                apiKey: apiKey,
                secret: secret
            };

            if (exchangesPasswordRequired.includes(selectedExchange))
                props.password = password;

            const result = await exchangesConnectionsMePost(props);

            if (result.isSuccess) {
                await fetchExchanges();

                setConfirmButtonState('success');
                setPopUpText(t('sucess-exchange-connect'));
                setPopUpLevel('success');
                setPopUpDuration(3000);

                onClose();

            } else {
                setConfirmButtonState('error');
                setPopUpLevel('error');
                setPopUpDuration(3000);

                if (result.status === 403) {
                    setPopUpText(t('error-exchange-connect'));
                }
                else {
                    setPopUpText(t(`api_codes.default.${result.status}_0`));
                }

            }
        } catch (error) {
            setPopUpText(t('unexpected-error'));
            setPopUpLevel('error');
            setPopUpDuration(3000);
        } finally {
            clearForm();
        }
    };


    const handleCancel = () => {
        clearForm();
        onClose();
    };


    const buildExchangesFilter = () => {
        return availableExchangesList.map(exchange => ({
            label: exchange.name,
            value: exchange.slug
        }));
    };


    return (
        <Modal
            title={<Title variation='secondary' txt='Connect Exchange' />}
            open={open}
            onCancel={handleCancel}
            footer={null}
            closeIcon={<CloseOutlined />}
            centered
        >
            <form name='add-exchange-connection' onSubmit={handleConfirm}>

                <Column fill='width'>
                    <p>{t('select-exchange')}</p>

                    <Select
                        id='add-exchange-connection-select-exchange'
                        onChange={changeSelectedExchange}
                        options={buildExchangesFilter()}
                        default={selectedExchange?.slug}
                        variant='borderless'
                        placeholder={t('exchange.p')}
                    />
                </Column>

                <Column fill='width'>
                    <p>{t('enter-api-key')}</p>

                    <Input
                        id='add-exchange-connection-api-key'
                        onChange={(e) => setApiKey(e.target.value)}
                        placeholder={t('api-key')}
                        variation='text'
                        value={apiKey}
                    />
                </Column>

                <Column fill='width'>
                    <p>{t('enter-secret')}</p>

                    <Input
                        id='add-exchange-connection-secret'
                        onChange={(e) => setSecret(e.target.value)}
                        placeholder={t('secret.s')}
                        variation='text'
                        value={secret}
                    />
                </Column>

                {exchangesPasswordRequired.includes(selectedExchange)
                    ? <Column fill='width'>
                        <p>{t('enter-password')}</p>

                        <Input
                            id='add-exchange-connection-api-key'
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder={t('password.s')}
                            variation='text'
                            value={password}
                        />
                    </Column>
                    : <></>
                }

                <Row
                    fill='width'
                    j='end'
                >
                    <Button id='add-exchange-connection-confirm-button'
                        state={confirmButtonState}
                        type='submit'
                    >{t('Confirm')}</Button>
                </Row>
            </form>
        </Modal>
    );
};

export default ExchangeConnectionsModal;
