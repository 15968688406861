/* eslint-disable @typescript-eslint/no-unused-vars */

import $ from 'jquery';
import ccxt from 'ccxt';

import { exchangesConnectionsMeGet } from 'apis/imports';

import { appEnv, userService } from 'constants';


export const fetchAndSaveInStorageGlobalExchanges = () => {
    $.get(`${userService.hosts[appEnv]}/exchanges`).then(response => {
        sessionStorage.setItem('exchanges', JSON.stringify(response));
    });
};


export const fetchAndSaveInStorageUserConnectedExchanges = async () => {
    let result = await exchangesConnectionsMeGet({ simpleFormat: true });
    let { response } = result;
    let exchanges = Object.entries(response.items).reduce((conn_exchanges, [exchange_slug, is_enabled]) => {

        if (!is_enabled) return conn_exchanges;
        let globalsExchanges = getGlobalExchanges({ asObject: true });

        if (!(exchange_slug in globalsExchanges)) return conn_exchanges;
        conn_exchanges[exchange_slug] = globalsExchanges[exchange_slug];

        return conn_exchanges;
    }, {});
    sessionStorage.setItem('connected_exchanges', JSON.stringify(exchanges));
};


export const getGlobalExchanges = ({ asObject = false }) => {

    let exchanges = sessionStorage.getItem('exchanges');

    if (!exchanges || exchanges == 'undefined') exchanges = {};
    else exchanges = JSON.parse(exchanges);

    if (asObject) return exchanges;
    return Object.values(exchanges || {});
};


export const getUserConnectedExchanges = ({ asObject = false }) => {

    let exchanges = sessionStorage.getItem('connected_exchanges');

    if (!exchanges || exchanges == 'undefined') exchanges = {};
    else exchanges = JSON.parse(exchanges);

    if (asObject) return exchanges;
    return Object.values(exchanges || {});
};


export const exchangeConstructor = (exchange, type = 'basic') => {

    const exchanges = getGlobalExchanges({ asObject: true });

    // Check if exchange is a string
    if (typeof exchange !== 'string') {
        console.error('Exchange must be a string');
        return;
    }

    exchange = exchange.toLowerCase();

    // Check if exchange exists
    if (!exchanges[exchange]) {
        console.error(`Exchange "${exchange}" is not supported`);
        return;
    }

    try {
        var exchangeObject;

        if (type == 'pro') exchangeObject = new ccxt.pro[exchange]({ timeout: 30000 });
        else exchangeObject = new ccxt[exchange]({ timeout: 30000 });

        return exchangeObject;
    }
    catch (e) {
        console.error(e);
    }
};
