/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { Column, Input, Panel, Row, Title, SwitchableIcon, Label, ExchangesPill } from 'components/imports';
import { MoveLeftArrowSvg, MoveRightArrowSvg, RefreshSvg } from 'icons/imports';
import { currencyFormatter, percentageFormatter } from 'utils/formatters';
import { getAssetCurrency } from 'utils/assets-currencies';
import { portfoliosMeGet, portfoliosMePost } from 'apis/imports'; // Importe corretamente

import { buildExchangesStorageUrl } from 'icons/imports';

import Skeleton from 'react-loading-skeleton';


const PortfolioPanel = (props) => {
    const { t } = useTranslation();

    // Estados
    const [coins, setCoins] = useState([]);
    const [coinsSearched, setCoinsSearched] = useState([]);
    const [searchCoins, setSearchCoins] = useState('');
    const [allInWalletSelect, setAllInWalletSelect] = useState('');
    const [allOutWalletSelect, setAllOutWalletSelect] = useState('');
    const [loadingPortfolio, setLoadingPortfolio] = useState(true);
    const [processingAction, setProcessingAction] = useState(false); // Para ações de carregamento

    const [inWalletCheckedItems, setInWalletCheckedItems] = useState([]);
    const [outWalletCheckedItems, setOutWalletCheckedItems] = useState([]);

    const [inWalletCount, setInWalletCount] = useState(0);
    const [outWalletCount, setOutWalletCount] = useState(0);

    const [inWalletLimit, setInWalletLimit] = useState(20);

    const refreshIcon = <RefreshSvg className={'icon-svg refresh' + (loadingPortfolio ? ' spinning' : '')} />;


    // Fetch initial portfolio data
    useEffect(() => {
        fetchPortfolio();
    }, []);


    // Atualizar coinsSearched sempre que coins ou searchCoins mudarem
    useEffect(() => {

        coins.forEach(coin => {
            let pairInTicker = props.tickers?.[props.selectedExchange]?.[coin.symbol + '/' + props.selectedCurrency];
            if (pairInTicker) {
                coin.pnl = pairInTicker.pnls[props.selectedTimeframe]?.[1];
                coin.price = pairInTicker.price;
            }
            else {
                coin.pnl = null;
                coin.price = null;
            }
        });

        const txt = searchCoins.trim().toLowerCase();
        if (txt === '') {
            setCoinsSearched([...coins]);
        } else {
            const found = coins.filter(coin => {
                const symbol = coin.symbol.toLowerCase();
                const name = coin.name ? coin.name.toLowerCase() : '';
                return symbol.includes(txt) || name.includes(txt);
            });
            setCoinsSearched(found);
        }
    }, [props.tickers, props.selectedExchange, props.selectedCurrency, props.selectedTimeframe, searchCoins, coins]);


    // Atualizar os estados de seleção total baseado nos checkboxes individuais
    useEffect(() => {
        const inWalletChecked = coinsSearched.filter(coin => coin.checked && coin.inWallet);
        const outWalletChecked = coinsSearched.filter(coin => coin.checked && !coin.inWallet);

        setInWalletCheckedItems(inWalletChecked.map(coin => coin.symbol));
        setOutWalletCheckedItems(outWalletChecked.map(coin => coin.symbol));

        setInWalletCount(coinsSearched.filter(coin => coin.inWallet).length);
        setOutWalletCount(coinsSearched.filter(coin => !coin.inWallet).length);

        const allInSelected = coinsSearched.filter(coin => coin.inWallet).length > 0 &&
            coinsSearched
                .filter(coin => coin.inWallet)
                .every(coin => coin.checked);
        const allOutSelected = coinsSearched.filter(coin => !coin.inWallet).length > 0 &&
            coinsSearched
                .filter(coin => !coin.inWallet)
                .every(coin => coin.checked);

        setAllInWalletSelect(allInSelected ? 'checked' : (inWalletChecked.length > 0 ? 'indeterminate' : ''));
        setAllOutWalletSelect(allOutSelected ? 'checked' : (outWalletChecked.length > 0 ? 'indeterminate' : ''));

    }, [coinsSearched]);


    // Função para buscar o portfólio
    const fetchPortfolio = async () => {
        setLoadingPortfolio(true);
        const result = await portfoliosMeGet();

        if (result.isSuccess) {
            const { available, monitored } = result.response.assets;

            const availableCoins = available.map(item => {
                const symbol = Object.keys(item)[0];
                const currency = getAssetCurrency(symbol) || { name: symbol };
                return {
                    symbol: symbol,
                    name: currency.name,
                    exchanges: item[symbol].exchanges,
                    inWallet: false,
                    checked: false,
                };
            });

            const monitoredCoins = monitored.map(item => {
                const symbol = Object.keys(item)[0];
                const currency = getAssetCurrency(symbol) || { name: symbol };

                return {
                    symbol: symbol,
                    name: currency.name,
                    exchanges: item[symbol].exchanges,
                    inWallet: true,
                    checked: false,
                };
            });

            const allCoins = [...monitoredCoins, ...availableCoins];
            setCoins(allCoins);
        }

        setLoadingPortfolio(false);
    };


    // Função para encontrar o índice de um coin pelo símbolo
    const getCoinIndexBySymbol = (symbol) => {
        return coins.findIndex(coin => coin.symbol.toLowerCase() === symbol.toLowerCase());
    };


    // Manipulador de mudança de busca
    const handleSearchCoinsChange = (event) => {
        setSearchCoins(event.target.value);
    };


    // Manipulador para selecionar/desselecionar todos os ativos disponíveis
    const handleToggleSelectAllOutWallet = () => {
        const newBool = allOutWalletSelect !== 'checked';

        const updatedList = coins.map(coin => {
            if (!coin.inWallet) {
                return { ...coin, checked: newBool };
            }
            return coin;
        });

        setCoins(updatedList);
    };

    // Manipulador para selecionar/desselecionar todos os ativos monitorados
    const handleToggleSelectAllInWallet = () => {
        const newBool = allInWalletSelect !== 'checked';

        const updatedList = coins.map(coin => {
            if (coin.inWallet) {
                return { ...coin, checked: newBool };
            }
            return coin;
        });

        setCoins(updatedList);
    };


    // Manipulador para selecionar/desselecionar um único ativo
    const handleToggleCoinCheck = (symbol) => {
        const coinIndex = getCoinIndexBySymbol(symbol);

        if (coinIndex === -1) return;

        const updatedList = [...coins];
        updatedList[coinIndex].checked = !updatedList[coinIndex].checked;

        setCoins(updatedList);
    };


    // Manipulador para adicionar ativos à carteira (monitorados)
    const handleSendIntoWallet = async () => {

        setProcessingAction(true);

        const result = await portfoliosMePost(outWalletCheckedItems, { remove: false });

        console.log(result);

        if (result.isSuccess) {
            setOutWalletCheckedItems([]);
            fetchPortfolio();
        }

        setProcessingAction(false);
    };


    // Manipulador para remover ativos da carteira (monitorados)
    const handleTakeOutOfWallet = async () => {

        setProcessingAction(true);

        const result = await portfoliosMePost(inWalletCheckedItems, { remove: true });

        if (result.isSuccess) {
            setInWalletCheckedItems([]);
            fetchPortfolio();
        }

        setProcessingAction(false);
    };


    const arrowLeftSvg = <MoveLeftArrowSvg className='icon-svg' title='Adicionar à carteira' />;
    const arrowRightSvg = <MoveRightArrowSvg className='icon-svg' title='Remover da carteira' />;


    return (
        <Panel id='portfolio-panel' fill='all'>
            <Column fill='all'>
                <Row fill='width' g='2'>
                    <Title variation='secondary' txt={t('wallet')} />

                    <Input
                        id='search-coin-trend'
                        onChange={handleSearchCoinsChange}
                        placeholder={t('search.coin')}
                        variation='searchbar'
                        value={searchCoins}
                    />

                    <SwitchableIcon
                        id='refresh-balances'
                        onToggle={() => fetchPortfolio()}
                        staticImage={refreshIcon}
                    />
                </Row>

                <hr />

                <Row
                    a='start'
                    className='content'
                    fill='all'
                >
                    {/* Ativos Disponíveis */}
                    <Column
                        id='available-coins'
                        fill='all'
                    >
                        <Row className='table-header'>
                            <p><b>{t('cryptocurrency.available')}</b></p>
                        </Row>

                        <Row
                            className='portfolio-header'
                            fill='width'
                            j='between'
                        >
                            <Row className='col-0' g='1'>
                                <Checkbox
                                    checked={allOutWalletSelect === 'checked'}
                                    indeterminate={allOutWalletSelect === 'indeterminate'}
                                    onChange={handleToggleSelectAllOutWallet}
                                >
                                    <p><b>{t('cryptocurrency.s')}</b></p>
                                </Checkbox>
                            </Row>

                            <Row className='col-1' g='1' j='end'>
                                <p><b>{t('price.s')} / {t('variation.s')}</b></p>

                                <Row>
                                    <img className='icon-svg' src={buildExchangesStorageUrl(props.selectedExchange)} />

                                    <p className='money-currency'>{props.selectedTimeframe}</p>
                                </Row>
                            </Row>

                            <Row className='col-2' g='1'>
                                <p><b>{t('exchange.p')}</b></p>
                            </Row>
                        </Row>

                        <Column className='portfolio-col' scroll fill='all'>

                            {loadingPortfolio ? (
                                [0, 1, 2, 3, 4].map((index) => (
                                    <>
                                        <hr />

                                        <Row fill='width' j='between'>
                                            <Row g='1'>
                                                <Skeleton
                                                    height='1.25rem'
                                                    width='1.25rem'
                                                />

                                                <Skeleton
                                                    height='2rem'
                                                    width='2rem'
                                                />

                                                <Skeleton
                                                    height='1.25rem'
                                                    width='5rem'
                                                />
                                            </Row>

                                            <Row g='1'>
                                                <Skeleton
                                                    height='1.25rem'
                                                    width='8rem'
                                                />

                                                <Skeleton
                                                    height='1.25rem'
                                                    width='3rem'
                                                />
                                            </Row>

                                            <Skeleton
                                                height='1.5rem'
                                                width='2rem'
                                            />
                                        </Row>
                                    </>
                                ))
                            ) : coinsSearched.filter(coin => !coin.inWallet).length > 0 ? (
                                coinsSearched
                                    .filter(coin => !coin.inWallet)
                                    .map((data) => {
                                        const { symbol, name, exchanges, price, pnl } = data;

                                        return (
                                            <>
                                                <hr />

                                                <Row
                                                    fill='width'
                                                    j='between'
                                                    key={symbol}
                                                    g='1'
                                                >
                                                    <Row className='col-0' g='1'>
                                                        <Checkbox
                                                            id={`${symbol}-check`}
                                                            checked={data.checked}
                                                            onChange={() => handleToggleCoinCheck(symbol)}
                                                        />

                                                        <Label
                                                            variation='symbol-and-name'
                                                            value={{ symbol, name }}
                                                        />
                                                    </Row>

                                                    <Row className='col-1' j='end'>
                                                        <p className='currency'><b>{price || '-'}</b></p>

                                                        <p className="money-currency">{props.selectedCurrency}</p>

                                                        {pnl != undefined && pnl != null
                                                            ? <p className={'trend-' + (pnl >= 0 ? 'up' : 'down') + '-text'}>
                                                                <b>{percentageFormatter({ v: pnl, multiply: false })}</b>
                                                            </p>
                                                            : <p>-- %</p>
                                                        }
                                                    </Row>

                                                    <Row className='col-2' j='end'>
                                                        <ExchangesPill exchanges={exchanges} />
                                                    </Row>
                                                </Row>
                                            </>
                                        );
                                    })
                            ) : (
                                <Column fill='all'>
                                    <p>{t('Move assets to your monitored portfolio!')}</p>
                                </Column>
                            )}
                        </Column>

                        <Row flex='wrap'>
                            <p className='side-label'>{t('selected')}</p>

                            <p><b>{t('x-of-y').replace('{x}', outWalletCheckedItems.length).replace('{y}', outWalletCount)}</b></p>
                        </Row>
                    </Column>

                    {/* Botões de Movimentação */}
                    <Column id='move-buttons' j='center' fill='height' h='24'>
                        <SwitchableIcon
                            className={`move-button take-out-of-wallet ${processingAction ? 'disabled' : ''}`}
                            staticImage={arrowLeftSvg}
                            onToggle={handleTakeOutOfWallet}
                            disabled={inWalletCheckedItems.length === 0 || processingAction}
                        />

                        <SwitchableIcon
                            className={`move-button send-into-wallet ${processingAction ? 'disabled' : ''}`}
                            staticImage={arrowRightSvg}
                            isOn={!processingAction}
                            onToggle={handleSendIntoWallet}
                            disabled={outWalletCheckedItems.length === 0 || processingAction}
                        />
                    </Column>

                    {/* Ativos Monitorados */}
                    <Column id='monitored-coins' fill='all'>
                        <Row>
                            <p><b>{t('cryptocurrency.monitored')}</b></p>
                        </Row>

                        <Row
                            className='portfolio-header'
                            fill='width'
                            j='between'
                        >
                            <Row className='col-0' g='1'>
                                <Checkbox
                                    checked={allInWalletSelect === 'checked'}
                                    indeterminate={allInWalletSelect === 'indeterminate'}
                                    onChange={handleToggleSelectAllInWallet}
                                >
                                    <p><b>{t('cryptocurrency.s')}</b></p>
                                </Checkbox>
                            </Row>

                            <Row className='col-1' g='1' j='end'>
                                <p><b>{t('price.s')} / {t('variation.s')}</b></p>

                                <Row>
                                    <img className='icon-svg' src={buildExchangesStorageUrl(props.selectedExchange)} />

                                    <p className='money-currency'>{props.selectedTimeframe}</p>
                                </Row>
                            </Row>

                            <Row className='col-2' g='1'>
                                <p><b>{t('exchange.p')}</b></p>
                            </Row>
                        </Row>

                        <Column className='portfolio-col' fill='all' scroll>
                            {loadingPortfolio ? (
                                [0, 1, 2, 3, 4].map((index) => (
                                    <>
                                        <hr />

                                        <Row fill='width' j='between'>
                                            <Row g='1'>
                                                <Skeleton
                                                    height='1.25rem'
                                                    width='1.25rem'
                                                />

                                                <Skeleton
                                                    height='2rem'
                                                    width='2rem'
                                                />

                                                <Skeleton
                                                    height='1.25rem'
                                                    width='5rem'
                                                />
                                            </Row>

                                            <Row g='1'>
                                                <Skeleton
                                                    height='1.25rem'
                                                    width='8rem'
                                                />

                                                <Skeleton
                                                    height='1.25rem'
                                                    width='3rem'
                                                />
                                            </Row>

                                            <Skeleton
                                                height='1.5rem'
                                                width='2rem'
                                            />
                                        </Row>
                                    </>
                                ))
                            ) : coinsSearched.filter(coin => coin.inWallet).length > 0 ? (
                                coinsSearched
                                    .filter(coin => coin.inWallet)
                                    .map((data) => {
                                        const { symbol, name, exchanges, price, pnl } = data;

                                        return (
                                            <>
                                                <hr />

                                                <Row
                                                    fill='width'
                                                    j='between'
                                                    key={symbol}
                                                    g='1'
                                                >
                                                    <Row className='col-0' g='1'>
                                                        <Checkbox
                                                            id={`${symbol}-check`}
                                                            checked={data.checked}
                                                            onChange={() => handleToggleCoinCheck(symbol)}
                                                        />

                                                        <Label
                                                            variation='symbol-and-name'
                                                            value={{ symbol, name }}
                                                        />
                                                    </Row>

                                                    <Row className='col-1' j='end'>
                                                        <p className='currency'><b>{price || '-'}</b></p>

                                                        <p className="money-currency">{props.selectedCurrency}</p>

                                                        {pnl != undefined && pnl != null
                                                            ? <p className={'trend-' + (pnl >= 0 ? 'up' : 'down') + '-text'}>
                                                                <b>{percentageFormatter({ v: pnl, multiply: false })}</b>
                                                            </p>
                                                            : <p>-- %</p>
                                                        }
                                                    </Row>

                                                    <Row className='col-2' j='end'>
                                                        <ExchangesPill exchanges={exchanges} />
                                                    </Row>
                                                </Row>
                                            </>
                                        );
                                    })
                            ) : (
                                <Column fill='all'>
                                    <p>{t('No monitored assets')}</p>
                                </Column>
                            )}
                        </Column>

                        <Row g='2'>
                            <Row flex='wrap'>
                                <p className='side-label'>{t('selected')}</p>

                                <p><b>{t('x-of-y').replace('{x}', inWalletCheckedItems.length).replace('{y}', inWalletCount)}</b></p>
                            </Row>

                            <Row flex='wrap'>
                                <p className='side-label'>{t('total')}</p>

                                <p><b>{t('x-of-y').replace('{x}', inWalletCount).replace('{y}', inWalletLimit)}</b></p>
                            </Row>
                        </Row>
                    </Column>
                </Row>
            </Column>
        </Panel>
    );
};


PortfolioPanel.propTypes = {
    selectedExchange: PropTypes.string,
    selectedCurrency: PropTypes.string,
    selectedTimeframe: PropTypes.string,
    tickers: PropTypes.object
};

export default PortfolioPanel;
