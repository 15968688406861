import $ from 'jquery';

import { appEnv, userService as users } from 'constants';

import { getAuthorization } from 'utils/cookies';


const usersMeAvatarPatch = async ({ image }) => {

    const method = 'patch';
    const { hosts, version } = users;

    const host = hosts[appEnv];

    const url = `${host}/api/${version}/users/me/avatar`;

    const auth = await getAuthorization();

    if (auth === 'undefined') {
        return {
            isSuccess: false,
            data: {
                hasJwt: false
            },
            errorType: 'Unauthorized',
            status: 401
        };
    }

    // Criar um FormData para enviar o arquivo
    const formData = new FormData();
    formData.append('file', image); // 'file' é o nome do campo que o servidor espera

    var settings = {
        url: url,
        method: method,
        timeout: 0,
        processData: false, // Evitar que o jQuery processe o FormData
        contentType: false, // Deixar o navegador definir o tipo correto do conteúdo
        beforeSend: (xhr) => xhr.setRequestHeader('Authorization', auth),
        data: formData // Enviar o FormData
    };

    try {
        const response = await $.ajax(settings);

        return {
            data: {
                hasJwt: true    
            },
            isSuccess: true,
            response: response
        };

    } catch (error) {
        let status = 0;
        let errorType = '';

        if (error && error.status) {

            status = error.status;
            try {
                const response = JSON.parse(error.responseText);
                if ('detail' in response && 'type' in response.detail)
                    errorType = response.detail.type;
            } catch (e) { errorType = 'InternalServerError'; }
        }

        return {
            isSuccess: false,
            data: {
                hasJwt: true
            },
            errorType,
            status,
        };
    }
};

export { usersMeAvatarPatch };
