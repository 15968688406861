import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { TourProvider } from '@reactour/tour';
import { CookiesProvider } from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    /* 
    O Strict mode é utilizado somente em ambiente de desenvolvimento, ele roda cada componente 2 vezes, 
    o que pode ser útil para detectar problemas no código, porém, foi desativado pois realiza uma mesma chamada de API 2 vezes.
  */
    //<React.StrictMode>
    <CookiesProvider 
        defaultSetOptions={{ path: '/' }}>
        <TourProvider 
            steps={[]}>
            <App />
        </TourProvider>
    </CookiesProvider>
    //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
