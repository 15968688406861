/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

// Ant Design Imports
import { Tooltip, Checkbox } from 'antd';

// Custom Components and Utilities
import { Column, Row, List, Panel, SwitchableIcon, Title, Label } from 'components/imports';

import { ExchangeConnectionSvg } from 'images/imports';

import { buildCurrencyStorageUrl } from 'icons/imports';

import { HideSvg, InfoSvg, ShowSvg, LinkSvg, RefreshSvg } from 'icons/imports';
import { currencyFormatter } from 'utils/formatters';

// Skeleton
import Skeleton from 'react-loading-skeleton';

import { getAssetBase } from 'utils/assets-bases';

import { balancesMeGet } from 'apis/imports';


const BalancesPanel = (props) => {

    const { t } = useTranslation();

    const { pageId } = props;

    const [userAssets, setUserAssets] = useState({});
    const [userDataAux, setUserDataAux] = useState({});

    const [showWallet, setShowWallet] = useState(true);
    const [showWalletInfo, setShowWalletInfo] = useState(false);

    const [hideSmallPositions, setHideSmallPositions] = useState(true);

    const [loadingAssets, setLoadingAssets] = useState(true);

    const [portfolioData, setPortfolioData] = useState([]);

    const handleToggleWallet = () => setShowWallet(!showWallet);

    const hideIcon = <HideSvg className='icon-svg' />;
    const infoIcon = <InfoSvg className='icon-svg' />;
    const showIcon = <ShowSvg className='icon-svg' />;
    const linkIcon = <LinkSvg className='icon-svg' />;
    const refreshIcon = <RefreshSvg className={'icon-svg refresh' + (loadingAssets ? ' spinning' : '')} />;


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            getPortfolioData();
        }
    }, [location.pathname]);


    useEffect(() => {
        let data = portfolioData;
        let dataAux = { ...userDataAux };

        const { selectedCurrency, selectedExchange, selectedTimeframe, tickers } = props;

        if (!data || !data.length || !tickers) return;

        data.forEach((asset) => {
            const pair = `${asset.symbol}/${selectedCurrency}`;

            if (asset.symbol === selectedCurrency) asset.selExcLast = 1;
            else if (selectedExchange in tickers && pair in tickers[selectedExchange])
                asset.selExcLast = tickers[selectedExchange][pair].price;
            else asset.selExcLast = dataAux[asset.symbol]?.selExcLast || 0;

            asset.perExchange = Object.entries(asset.perExchange).reduce(
                (acc, [exchange, values]) => {
                    
                    let { total } = values;

                    acc[exchange] = {
                        balance: 0,
                        price: null,
                        pnl: null,
                        total
                    };

                    if (asset.symbol === selectedCurrency) {
                        acc[exchange].price = 1;
                        acc[exchange].balance = total;
                        acc[exchange].total = total;
                    } else if (exchange in tickers && pair in tickers[exchange]) {
                        let { price, pnls } = tickers[exchange][pair];
                        let balance = price * total;

                        let pnl = pnls[selectedTimeframe]?.[1];

                        acc[exchange].price = price;
                        acc[exchange].pnl = pnl;
                        acc[exchange].balance = balance;
                    }

                    return acc;
                }, {});

            asset.maxPnl = Math.max(
                ...Object.values(asset.perExchange).map((e) => e.pnl || 0)
            );

            dataAux[asset.symbol] = asset;
        });

        setUserDataAux(dataAux);

        let dataList = Object.values(dataAux);
        let totalBalances = { total: 0, perExchange: {} };

        dataList = dataList.filter(asset => asset.total > 0);

        dataList.forEach(asset => {

            asset.balance = asset.selExcLast * asset.total;
            totalBalances.total += asset.balance;

            Object.entries(asset.perExchange).forEach(([exchange, values]) => {
                if (!(exchange in totalBalances.perExchange))
                    totalBalances.perExchange[exchange] = 0;
                totalBalances.perExchange[exchange] += values.balance;
            });
        });

        data = dataList.sort((a, b) => b.balance - a.balance);

        setUserAssets({
            totalBalances,
            data
        });

    }, [props.tickers, portfolioData]);


    const getPortfolioData = async () => {

        setLoadingAssets(true);

        const result = await balancesMeGet();

        if (!result.isSuccess) return;

        var values = result.response;

        const data = values.map((value) => {
            const assetBase = getAssetBase({ symbol: value.symbol });
            return ({
                ...value,
                name: assetBase.name,
                icon: buildCurrencyStorageUrl(assetBase.slug)
            });
        });

        setPortfolioData(data);
        setLoadingAssets(false);
    };


    const adaptPerExchangeBalance = (obj) => {
        let list = Object.entries(obj);
        list.sort((a, b) => b[1] - a[1]);
        return list;
    };


    return (
        <>
            <Panel id='balances'
                fill='width'
                fit='height'
            >
                <Row
                    className='title'
                    fill='width'
                    fit='height'
                    j='between'
                >
                    <Row
                        a='center'
                        fit='width'
                        j='start'
                    >
                        <Title variation='tertiary' txt={t('balance.p')} />

                        <Tooltip
                            color='#1D96EE'
                            open={showWalletInfo}
                            title={
                                <>
                                    <label><b>{t('your-balance.p')}</b></label>

                                    <p>{t('balances-info')}</p>
                                </>
                            }
                        >
                            <Row id='icon-container' onClick={() => setShowWalletInfo(!showWalletInfo)}>
                                {infoIcon}
                            </Row>
                        </Tooltip>
                    </Row>

                    <Row
                        a='center'
                        fit='width'
                        j='end'
                    >
                        <SwitchableIcon
                            onImage={showIcon}
                            onToggle={handleToggleWallet}
                            offImage={hideIcon}
                            isOn={showWallet}
                        />

                        <SwitchableIcon
                            id='refresh-balances'
                            onToggle={getPortfolioData}
                            staticImage={refreshIcon}
                        />
                    </Row>
                </Row>

                <hr />
                <Row fill='width'>
                    <p><b>{t('total')} </b></p>

                    {loadingAssets ? (
                        <Skeleton
                            className='skeleton'
                            height={'1.5rem'}
                            width={'5rem'}
                        />
                    ) : <>
                        <p className={'fill-width money-value pri ' + (showWallet ? 'occult' : '')}>
                            ≈ <b>{currencyFormatter(userAssets?.totalBalances?.total || 0)}</b>
                        </p>
                        <p className='fill-width money-currency'>
                            {props.selectedCurrency}
                        </p>
                    </>
                    }
                </Row>

                {loadingAssets ? (
                    <Column>
                        <Row>
                            <Skeleton
                                circle
                                className='skeleton'
                                height={'1.75rem'}
                                width={'1.75rem'}
                            />
                            <Skeleton
                                className='skeleton'
                                height={'1.5rem'}
                                width={'10rem'}
                            />
                        </Row>
                        <Row>
                            <Skeleton
                                circle
                                className='skeleton'
                                height={'1.75rem'}
                                width={'1.75rem'}
                            />
                            <Skeleton
                                className='skeleton'
                                height={'1.5rem'}
                                width={'10rem'}
                            />
                        </Row>
                    </Column>
                ) : (
                    <Column
                        scroll
                        style={{ maxHeight: '10rem' }}
                    >
                        {userAssets?.totalBalances?.perExchange && (
                            <>
                                {adaptPerExchangeBalance(userAssets.totalBalances.perExchange).map(
                                    ([exchangeSlug, total], index) => {

                                        let exchange = props.exchanges.find(e => e.slug === exchangeSlug);

                                        return (
                                            <Row key={index}
                                                fill='width'
                                            >
                                                {exchange ?
                                                    (<Label
                                                        value={{ exchange }}
                                                        variation='exchange'
                                                    />)
                                                    : exchangeSlug
                                                }

                                                <p className={'fill-width money-value sec ' + (showWallet ? 'occult' : '')}>
                                                    ≈ <b>{currencyFormatter(total)}</b>
                                                </p>

                                                <p className='fill-width money-currency'>
                                                    {props.selectedCurrency}
                                                </p>
                                            </Row>
                                        );
                                    }
                                )}
                            </>
                        ) || (<></>)}
                    </Column>
                )}
            </Panel>

            <Panel
                fill='all'
                id='positions'
            >
                <Row
                    fill='width'
                    j='between'
                >
                    <Title variation='tertiary' txt={t('position.p')} />

                    <Checkbox
                        checked={hideSmallPositions}
                        onChange={() => setHideSmallPositions(!hideSmallPositions)}
                    >
                        {t('hide-small-positions').replace('{currency}', props.selectedCurrency)}
                    </Checkbox>
                </Row>

                {loadingAssets || !props.isUserAuthenticated ? (
                    <div id='balances-assets-skeleton'>
                        {
                            [0, 1, 2, 3, 4].map((value, index) => (
                                <Row
                                    fill='width'
                                    j='between'
                                    key={'balances-assets-skeleton-' + index}
                                >
                                    <Row>
                                        <Skeleton
                                            className='skeleton'
                                            circle
                                            height={'35px'}
                                            style={{ marginBottom: '1rem' }}
                                            width={'35px'}
                                        />

                                        <Skeleton
                                            className='skeleton'
                                            height={'35px'}
                                            style={{ marginBottom: '1rem' }}
                                            width={'5rem'}
                                        />
                                    </Row>

                                    <Column
                                        a='end'
                                        g='0'
                                    >
                                        <Skeleton
                                            className='skeleton'
                                            height={'15px'}
                                            width={'5rem'}
                                        />

                                        <Skeleton
                                            className='skeleton'
                                            height={'15px'}
                                            style={{ marginBottom: '1rem' }}
                                            width={'6rem'}
                                        />
                                    </Column>
                                </Row>
                            ))
                        }
                    </div>
                ) : (!userAssets?.data?.length
                    ? <Column
                        a='center'
                        fill='width'
                        h='24'
                        j='center'
                    >
                        <Column
                            a='center'
                            fit='all'
                        >
                            <img className='empty-img' src={ExchangeConnectionSvg} />

                            <p style={{ textAlign: 'center' }}>
                                {t('message-signals-empty')}
                            </p>
                        </Column>
                    </Column>
                    : <List id='assets-list'
                        hideValues={showWallet}
                        hideSmallPositions={hideSmallPositions}
                        items={userAssets.data}
                        selectedCurrency={props.selectedCurrency}
                        selectedTimeframe={props.selectedTimeframe}
                        variation='positions'
                    />
                )}
            </Panel>
        </>
    );
};


BalancesPanel.propTypes = {
    pageId: PropTypes.string,
    isUserAuthenticated: PropTypes.bool,
    tickers: PropTypes.object,
    exchanges: PropTypes.array,
    selectedExchange: PropTypes.string,
    selectedCurrency: PropTypes.string,
    selectedTimeframe: PropTypes.string
};


export default BalancesPanel;
