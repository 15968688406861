import React  from 'react';

import './Rules.css';

import { Footer, Header } from 'components/imports';


const Rules = () => {

    return (
        <main className='rules'>
            <Header page='rules' />
            <aside>
                <div id='rules-gradient-container'>
                    <p className='page-title'>Rules</p>
                </div>
            </aside>
            <article>
            </article>
            <Footer />
        </main>
    );
};

export default Rules;
