import React from 'react';

import { useImageCropContext } from 'providers/ImageCropProvider';

import { Button, Row } from 'components/imports';

import { AddSvg, MinusSvg, RefreshSvg } from 'icons/imports';


export const ZoomSlider = () => {
    const { zoom, setZoom, handleZoomIn, handleZoomOut, max_zoom, min_zoom, zoom_step } =
        useImageCropContext();

    const addSvg = <AddSvg className='icon-svg' />;
    const minusSvg = <MinusSvg className='icon-svg' />;

    return (
        <Row
            fill='width'
            j='center'
        >
            <Button
                id='zoom-out'
                onClick={handleZoomOut}
                variation='tertiary'
            >
                {minusSvg}
            </Button>

            <input
                type='range'
                name='volju'
                min={min_zoom}
                max={max_zoom}
                step={zoom_step}
                value={zoom}
                onChange={e => setZoom(Number(e.target.value))}
            />

            <Button
                id='zoom-in'
                onClick={handleZoomIn}
                variation='tertiary'
            >
                {addSvg}
            </Button>
        </Row>
    );
};

export const RotationSlider = () => {
    const {
        rotation,
        setRotation,
        max_rotation,
        min_rotation,
        rotation_step,
        handleRotateAntiCw,
        handleRotateCw
    } = useImageCropContext();

    const refreshSvg = <RefreshSvg className='icon-svg' />;
    const refreshReverseSvg = <RefreshSvg className='icon-svg hor-flip' />;

    return (
        <Row
            fill='width'
            j='center'
        >
            <Button
                id='rotate-anti-cw'
                onClick={handleRotateAntiCw}
                variation='tertiary'
            >
                {refreshReverseSvg}
            </Button>

            <input
                type='range'
                name='volju'
                min={min_rotation}
                max={max_rotation}
                step={rotation_step}
                value={rotation}
                onChange={e => setRotation(Number(e.target.value))}
            />

            <Button
                id='rotate-cw'
                onClick={handleRotateCw}
                variation='tertiary'
            >
                {refreshSvg}
            </Button>
        </Row>
    );
};