import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Tabs, Row, Col, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import { languages } from 'constants';
import { getGlobalExchanges } from 'utils/exchanges';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { TabPane } = Tabs;

const QuickSettings = (props) => {

    const { t } = useTranslation();

    const selectedCurrency = props.selectedCurrency || 'USDT';
    const selectedExchange = props.selectedExchange || 'KuCoin';
    const selectedLanguage = props.selectedLanguage || 'en';

    const exchanges = getGlobalExchanges({});
    const assets = { currencies: {} };

    return (
        <Modal
            closeIcon={<CloseOutlined />}
            footer={null}
            onCancel={props.onClose}
            open={props.open}

            width={632}
        >
            <Tabs defaultActiveKey="3">
                <TabPane tab={t('conected-exchanges')} key="1">
                    <Row gutter={[16, 16]} justify="center" className='mt-8'>
                        {Object.entries(exchanges).map(([key, exchange]) => (
                            <Col key={key} xs={12} sm={8} md={6}>
                                <Button
                                    onClick={() => props.changeExchange(key)}
                                    className={selectedExchange === key ? 'selected' : ''}
                                >
                                    {exchange}
                                </Button>
                            </Col>
                        ))}
                    </Row>
                </TabPane>

                <TabPane tab={t('currency.s')} key="2">
                    <Row gutter={[2, 2]} justify="center" className='mt-8'>
                        {Object.entries(assets.currencies).map(([currency, type]) => (
                            <Col key={currency} xs={12} sm={8} md={6}>
                                <Button
                                    onClick={() => props.changeCurrency(currency)}
                                    className={selectedCurrency === currency ? 'selected' : ''}
                                >
                                    {currency}
                                    {type}
                                </Button>
                            </Col>
                        ))}
                    </Row>
                </TabPane>

                <TabPane tab={t('language.s')} key="3">
                    <Row gutter={[16, 16]} justify="center" className='mt-8'>
                        {Object.entries(languages).map(([key, lang]) => (
                            <Col key={key} xs={12} sm={8} md={6}>
                                <Button
                                    onClick={() => props.changeLanguage(key)}
                                    className={selectedLanguage === lang.i18nKey ? 'selected' : ''}
                                >
                                    {lang.label}
                                </Button>
                            </Col>
                        ))}
                    </Row>
                </TabPane>
            </Tabs>
        </Modal>
    );
};

QuickSettings.propTypes = {
    changeCurrency: PropTypes.func.isRequired,
    changeExchange: PropTypes.func.isRequired,
    changeLanguage: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedCurrency: PropTypes.string,
    selectedExchange: PropTypes.string,
    selectedLanguage: PropTypes.string
};

export default QuickSettings;