// utils/priceWatcher.js

import { userServiceWsHost, userServiceWsVersion } from 'constants';

class PriceWatcher {
    constructor() {
        this.assets = {};
        this.tickers = {};
        this.callbacks = [];
        this.websocket = null;
    }

    /**
     * Starts watching tickers.
     * @param {Object} options
     * @param {Function} [options.onUpdate] - Callback function to receive the data.
     */
    start({ onUpdate, userExchanges, targetTimeframe }) {
        console.log('Starting watcher');

        // Stop any existing WebSocket connection before starting a new one
        this.stop();

        // Set up the callback function
        if (onUpdate) {
            this.callbacks.push(onUpdate);
        }

        // Open WebSocket connection to consume the tickers data
        const url = `${userServiceWsHost}/ws/${userServiceWsVersion}/exchanges/tickers?exchange_slugs=${userExchanges}&timeframe=${targetTimeframe}`;
        this.websocket = new WebSocket(url);

        this.websocket.onopen = () => console.log('WebSocket exchanges/tickers connection opened');

        this.websocket.onmessage = (event) => {
            const tickersData = JSON.parse(event.data);
            this.tickers = tickersData;
            this.callbacks.forEach((cb) => cb(this.getTickers()));
        };

        this.websocket.onerror = (error) => console.error('WebSocket error:', error);

        this.websocket.onclose = (event) => {
            console.log('WebSocket exchanges/tickers  connection closed', event.code, event.reason);
            this.websocket = null; // Reset the WebSocket instance
        };
    }

    /**
     * Stops watching tickers.
     */
    stop() {
        if (this.websocket) {
            if (this.websocket.readyState === WebSocket.OPEN || this.websocket.readyState === WebSocket.CONNECTING) {
                console.log('Closing WebSocket connection');
                this.websocket.close(1000, 'Client requested closure');
            } else {
                console.log('WebSocket is not open or connecting, state:', this.websocket.readyState);
            }
        } else {
            console.log('No WebSocket instance to close');
        }

        this.websocket = null; // Ensure websocket is reset
        this.callbacks = [];   // Clear the callbacks as well
    }

    /**
     * Returns the current tickers data.
     */
    getTickers() {
        return this.tickers;
    }

    /**
     * Gets the list of assets from the tickers data.
     * @param {Object} tickers - The tickers data.
     * @returns {Array} - The list of bases.
     */
    getAssets(tickers) {
        const assets = {};
        for (const symbol in tickers) {
            const [base] = symbol.split('/');
            assets[base] = true;
        }
        return assets;
    }
}


export default PriceWatcher;
