import PropTypes from 'prop-types';
import $ from 'jquery';

import { appEnv, userService as users } from 'constants';
import { getAuthorization } from 'utils/cookies';

const atsModesPairsPost = async ({ mode, exchangeName, base, quote }) => {
    const method = 'post';
    const { hosts, version } = users;

    const host = hosts[appEnv];

    const url = `${host}/api/${version}/ats/me/modes/${mode}/pairs?exchange_name=${encodeURIComponent(exchangeName)}`;

    const auth = await getAuthorization();

    const pairData = {
        base: {
            symbol: base.symbol,
            type: base.type || 'Balance',
            allocation: base.allocation,
            has_enough: base.hasEnough,
        },
        quote: {
            symbol: quote.symbol,
            type: quote.type || 'Balance',
            allocation: quote.allocation,
            has_enough: quote.hasEnough,
        },
    };

    const jsonData = JSON.stringify(pairData);

    const settings = {
        url: url,
        method: method,
        timeout: 0,
        processData: false,
        contentType: 'application/json',
        data: jsonData,
        beforeSend: (xhr) => xhr.setRequestHeader('Authorization', auth),
    };

    try {
        const response = await $.ajax(settings);
        return { isSuccess: true, response };
    } catch (error) {
        let status = 0;
        let errorType = '';

        if (error && error.status) {
            status = error.status;
            try {
                const response = JSON.parse(error.responseText);
                if ('detail' in response && 'type' in response.detail) {
                    errorType = response.detail.type;
                }
            } catch (e) {
                errorType = 'InternalServerError';
            }
        }

        return {
            isSuccess: false,
            errorType,
            status,
        };
    }
};

atsModesPairsPost.propTypes = {
    mode: PropTypes.string.isRequired,
    exchangeName: PropTypes.string.isRequired,
    base: PropTypes.shape({
        symbol: PropTypes.string.isRequired,
        type: PropTypes.string,
        allocation: PropTypes.number.isRequired,
        hasEnough: PropTypes.bool.isRequired,
    }).isRequired,
    quote: PropTypes.shape({
        symbol: PropTypes.string.isRequired,
        type: PropTypes.string,
        allocation: PropTypes.number.isRequired,
        hasEnough: PropTypes.bool.isRequired,
    }).isRequired,
};

export { atsModesPairsPost };
