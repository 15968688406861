import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import i18n from 'locales/i18n';
import { useTranslation } from 'react-i18next';

import { Table, Select, DatePicker } from 'antd';

import {
    Page, Layout, Loading, MessagePopup, Row, Column, Panel,
    SwitchableIcon, Title
} from 'components/imports';

import { signalsUsersMeGet, ordersMeGet } from 'apis/imports';

import { RefreshSvg } from 'icons/imports';

import { timeframes } from 'constants';

import { getAssetBase } from 'utils/assets-bases';

import { getUserConnectedExchanges } from 'utils/exchanges';
import { getTrendsMarketcaps } from 'utils/trends-marketcaps';

import {
    getSelectedCurrency, getSelectedExchange, getSelectedTimeframe, getLanguage,
    getTheme, getTop100Filter
} from 'utils/cookies';

import usePriceWatcher from 'hooks/usePriceWatcher';

import './Trades.css';


const Trades = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'trades';

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);

    const [appLang] = useState(getLanguage());
    const [appTheme] = useState(getTheme());
    const [appSelectedExchange, setAppSelectedExchange] = useState(getSelectedExchange());
    const [appSelectedCurrency, setAppSelectedCurrency] = useState(getSelectedCurrency());
    const [appSelectedTimeframe, setAppSelectedTimeframe] = useState(getSelectedTimeframe());
    const [appTop100Filter, setAppTop100Filter] = useState(getTop100Filter());

    const [changingFilters, setChangingFilters] = useState(false);

    const [popUpDuration,] = useState(3000);
    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');

    const [userExchanges, setUserExchanges] = useState(getUserConnectedExchanges({}));

    const [tickers, setTickers] = useState({});

    const [loadingTickers, setLoadingTickers] = useState(true);
    const [loadingSignals, setLoadingSignals] = useState(true);
    const [loadingOrders, setLoadingOrders] = useState(true);

    const [tickerTapeCoins, setTickerTapeCoins] = useState([]);

    const [signalsData, setSignalsData] = useState([]);
    const [ordersData, setOrdersData] = useState([]);

    const { RangePicker } = DatePicker;

    const [signalsExchangesFilter, setSignalsExchangesFilter] = useState([]);
    const [signalsPairsFilter, setSignalsPairsFilter] = useState([]);
    const [signalsTimeframesFilter, setSignalsTimeframesFilter] = useState([]);
    const [signalsSideFilter, setSignalsSideFilter] = useState(null);
    const [signalsDateRangeFilter, setSignalsDateRangeFilter] = useState([]);

    const [ordersExchangesFilter, setOrdersExchangesFilter] = useState([]);
    const [ordersPairsFilter, setOrdersPairsFilter] = useState([]);
    const [ordersSideFilter, setOrdersSideFilter] = useState(null);
    const [ordersDateRangeFilter, setOrdersDateRangeFilter] = useState([]);

    const refreshSignalsIcon = <RefreshSvg className={'icon-svg refresh' + (loadingSignals ? ' spinning' : '')} />;
    const refreshOrdersIcon = <RefreshSvg className={'icon-svg refresh' + (loadingOrders ? ' spinning' : '')} />;

    
    usePriceWatcher(isUserAuthenticated, userExchanges, appSelectedCurrency, appSelectedTimeframe, setTickers);


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('trade.p')} - Smart Trade`;
            getSignals();
            getOrders();
        }
    }, [location.pathname]);


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {

        if (!(appSelectedExchange in tickers)) return;

        let trendsMarketcaps = {};

        if (appTop100Filter) {
            trendsMarketcaps = getTrendsMarketcaps({ asObject: true });
        }

        let tickerList = Object.entries(tickers[appSelectedExchange]).reduce((tickers, [pair, ticker]) => {
            const [base,] = pair.split('/');

            if (base.includes('USD')) return tickers;

            ticker.symbol = base;

            if (appTop100Filter && trendsMarketcaps) {
                let baseAsset = getAssetBase({ symbol: base });
                if (!(baseAsset.slug in trendsMarketcaps)) return tickers;
            }

            ticker.pnl = ticker.pnls[appSelectedTimeframe][1];

            tickers.push(ticker);

            return tickers;
        }, []);

        if (!tickerList.length) return;

        let exchangeTickers = tickers[appSelectedExchange];

        let updateTickerTapeCoins = tickerTapeCoins.map((ticker) => {

            if (!(ticker.pair in exchangeTickers)) return ticker;

            ticker.price = exchangeTickers[ticker.pair].price;
            ticker.pnl = exchangeTickers[ticker.pair].pnls[appSelectedTimeframe][1];

            return ticker;
        });

        setTickerTapeCoins(updateTickerTapeCoins);

    }, [tickers]);


    useEffect(() => {
        if (!changingFilters) setChangingFilters(true);
    }, [appTop100Filter, appSelectedExchange, appSelectedCurrency, appSelectedTimeframe]);


    useEffect(() => {

        if (!loadingTickers) setLoadingTickers(changingFilters);

    }, [changingFilters]);


    const getSignals = async () => {

        setLoadingSignals(true);

        const [since, until] = signalsDateRangeFilter;

        let args = {
            exchanges: signalsExchangesFilter,
            pairs: signalsPairsFilter,
            timeframes: signalsTimeframesFilter,
            side: signalsSideFilter,
            since: since.valueOf(),
            until: until.valueOf()
        };

        if (!signalsDateRangeFilter || signalsDateRangeFilter.length !== 2 || !signalsDateRangeFilter[0] || !signalsDateRangeFilter[1]) {
            args.limit = 10;
        }

        try {
            const result = await signalsUsersMeGet(args);

            if (result.isSuccess) {
                const response = result.response.items.map((item, index) => ({
                    key: index,
                    date: item.timestamp,
                    exchangeSlug: item.exchange_slug,
                    pair: item.pair,
                    side: item.side,
                    price: item.price,
                    timeframe: item.timeframe,
                }));

                setSignalsData(response);
            } else {
                setPopUpLevel('error');
                setPopUpText(t('error.fetch-signals'));
            }
        } catch (error) {
            setPopUpLevel('error');
            setPopUpText(t('error.network'));
        } finally {
            setLoadingSignals(false);
        }
    };

    const getOrders = async () => {

        try {
            const result = await ordersMeGet({
                exchanges: ordersExchangesFilter,
                pairs: ordersPairsFilter,
                side: ordersSideFilter,
                limit: 10
            });
    

            if (result.isSuccess) {
                const response = result.response.items.map((item, index) => ({
                    key: index,
                    date: item.timestamp,
                    exchangeSlug: item.exchange_slug,
                    pair: item.pair,
                    side: item.side,
                    price: item.price
                }));

                setOrdersData(response);
            } else {
                setPopUpLevel('error');
                setPopUpText(t('error.fetch-orders'));
            }
        } catch (error) {
            setPopUpLevel('error');
            setPopUpText(t('error.network'));
        } finally {
            setLoadingOrders(false);
        }
    };


    const signalsColumns = [
        { title: t('date.s'), dataIndex: 'date', key: 'date' },
        { title: t('exchange.s'), dataIndex: 'exchange', key: 'exchange' },
        { title: t('pair.s'), dataIndex: 'pair', key: 'pair' },
        { title: t('side.s'), dataIndex: 'side', key: 'side' },
        { title: t('price.s'), dataIndex: 'price', key: 'price' }
    ];


    const ordersColumns = [
        { title: t('date.s'), dataIndex: 'date', key: 'date' },
        { title: t('exchange.s'), dataIndex: 'exchange', key: 'exchange' },
        { title: t('pair.s'), dataIndex: 'pair', key: 'pair' },
        { title: t('side.s'), dataIndex: 'side', key: 'side' },
        { title: t('price.s'), dataIndex: 'price', key: 'price' }
    ];


    const buildSignalsExchangesFilter = () =>
        userExchanges.map((exchange) => ({
            label: exchange.name,
            value: exchange.slug,
        }));


    const buildSignalsPairsFilter = () => [
        { value: 'SUI/USDT', label: 'Sui/Tether USDt' },
        { value: 'STX/USDT', label: 'Stacks/Tether USDt' },
        { value: 'JUP/USDT', label: 'Jupiter/Tether USDt' },
        { value: 'WLD/USDT', label: 'WLD/Tether USDt' },
    ];


    const buildSignalsTimeframesFilter = () =>
        timeframes.map((timeframe) => ({
            label: timeframe,
            value: timeframe,
        }));


    const buildSignalsSideFilter = () => [
        { label: t('all-sides'), value: null },
        { label: t('buy.v'), value: 'buy' },
        { label: t('sell.s'), value: 'sell' },
    ];


    const buildOrdersExchangesFilter = () =>
        userExchanges.map((exchange) => ({
            label: exchange.name,
            value: exchange.slug,
        }));

    const buildOrdersPairsFilter = () => [
        { value: 'SUI/USDT', label: 'Sui/Tether USDt' },
        { value: 'STX/USDT', label: 'Stacks/Tether USDt' },
        { value: 'JUP/USDT', label: 'Jupiter/Tether USDt' },
        { value: 'WLD/USDT', label: 'WLD/Tether USDt' },
    ];

    const buildOrdersSideFilter = () => [
        { label: t('all-sides'), value: null },
        { label: t('buy.v'), value: 'buy' },
        { label: t('sell.s'), value: 'sell' },
    ];

    return (
        <Page id={pageId}>
            <Loading id={'overview'} />

            <MessagePopup
                level={popUpLevel}
                text={popUpText}
                duration={popUpDuration}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='full'
                setIsUserAuthenticated={setIsUserAuthenticated}
                setSelectedExchange={setAppSelectedExchange}
                setSelectedCurrency={setAppSelectedCurrency}
                setSelectedTimeframe={setAppSelectedTimeframe}
                setAppTop100Filter={setAppTop100Filter}
                setUserExchanges={setUserExchanges}
                showToolbar={true}
                showHeader={true}
                tickerTapeCoins={tickerTapeCoins.filter((ticker) => ticker.price > 0).slice(0, 10)}
            >
                <Row
                    a='start'
                    className='main-row'
                    fill='width'
                >
                    <Column
                        id='table-col'
                        fill='all'
                    >
                        <Panel id='filters-panel'
                            fill='width'
                            fit='height'
                        >
                            <Title id='title' txt={t('signal.p')} variation='secondary' />

                            <Row fill='width'>
                                <Select
                                    mode='multiple'
                                    maxCount={3}
                                    placeholder={t('exchange.p')}
                                    variant='borderless'
                                    style={{ flex: 1 }}
                                    options={buildSignalsExchangesFilter()}
                                    onChange={(value) => setSignalsExchangesFilter(value)}
                                />

                                <Select
                                    mode='tags'
                                    maxCount={3}
                                    placeholder={t('pair.p')}
                                    variant='borderless'
                                    style={{ flex: 1 }}
                                    options={buildSignalsPairsFilter()}
                                    onChange={(value) => setSignalsPairsFilter(value)}
                                />

                                <Select
                                    mode='multiple'
                                    maxCount={3}
                                    placeholder={t('timeframe.p')}
                                    variant='borderless'
                                    style={{ flex: 1 }}
                                    options={buildSignalsTimeframesFilter()}
                                    onChange={(value) => setSignalsTimeframesFilter(value)}
                                />

                                <Select
                                    placeholder={t('side.s')}
                                    variant='borderless'
                                    defaultValue={['all']}
                                    style={{ flex: 1 }}
                                    options={buildSignalsSideFilter()}
                                    onChange={(value) => setSignalsSideFilter(value == 'all' ? null : value)}
                                />

                                <RangePicker
                                    showTime={{ format: 'HH:mm' }}
                                    variant='borderless'
                                    onChange={(value) => setSignalsDateRangeFilter(value)}
                                    format="YYYY-MM-DD HH:mm"
                                    defaultValue={signalsDateRangeFilter}
                                />

                                <SwitchableIcon
                                    id='refresh-trades'
                                    onToggle={getSignals}
                                    staticImage={refreshSignalsIcon}
                                />
                            </Row>

                            <Table
                                columns={signalsColumns}
                                dataSource={signalsData}
                                loading={loadingSignals}
                                bordered
                                pagination={{ pageSize: 10 }}
                            />
                        </Panel>

                        <Panel id='filters-panel'
                            fill='width'
                            fit='height'
                        >
                            <Title id='title' txt={t('order.p')} variation='secondary' />

                            <Row fill='width'>
                                <Select
                                    mode='multiple'
                                    maxCount={3}
                                    placeholder={t('exchange.p')}
                                    variant='borderless'
                                    style={{ flex: 1 }}
                                    options={buildOrdersExchangesFilter()}
                                    onChange={(value) => setOrdersExchangesFilter(value)}
                                />

                                <Select
                                    mode='tags'
                                    maxCount={3}
                                    placeholder={t('pair.p')}
                                    variant='borderless'
                                    style={{ flex: 1 }}
                                    options={buildOrdersPairsFilter()}
                                    onChange={(value) => setOrdersPairsFilter(value)}
                                />

                                <Select
                                    placeholder={t('side.s')}
                                    variant='borderless'
                                    defaultValue={['all']}
                                    style={{ flex: 1 }}
                                    options={buildOrdersSideFilter()}
                                    onChange={(value) => setOrdersSideFilter(value == 'all' ? null : value)}
                                />

                                <RangePicker
                                    showTime={{ format: 'HH:mm' }}
                                    variant='borderless'
                                    onChange={(value) => setOrdersDateRangeFilter(value)}
                                    format="YYYY-MM-DD HH:mm"
                                    defaultValue={ordersDateRangeFilter}
                                />

                                <SwitchableIcon
                                    id='refresh-trades'
                                    onToggle={getOrders}
                                    staticImage={refreshOrdersIcon}
                                />
                            </Row>

                            <Table
                                columns={ordersColumns}
                                dataSource={ordersData}
                                loading={loadingOrders}
                                bordered
                                pagination={{ pageSize: 10 }}
                            />
                        </Panel>
                    </Column>
                </Row>
            </Layout>
        </Page>
    );
};

export default Trades;
