import React from 'react';

import './Footer.css';


const Footer = () => {
    return (
        <footer>2023 | Bronx Capital | We-Bronx - Smart Trade (v1.0.0)</footer>
    );
};

export default Footer;
