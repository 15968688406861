/* eslint-disable @typescript-eslint/no-unused-vars */
// Common Imports (used in multiple pages)
import React, { useEffect, useState } from 'react'; // React
import { useLocation } from 'react-router-dom'; // React Router

import i18n from 'locales/i18n';
import $ from 'jquery'; // JQuery

// React-i18next
import { useTranslation } from 'react-i18next';

// Reactour
import { useTour } from '@reactour/tour';

// Element Components
import {
    Column, CookiesPolicyModal, MessagePopup, Row
} from 'components/imports'; // Common
import { Layout, Loading, Page } from 'components/imports'; // Layout

// Shared Page Components
import { BalancesPanel } from 'components/imports';

// Page Components
import ArticlesPanel from './components/ArticlesPanel';
import TradesPanel from './components/TradesPanel';
import TopListPanel from './components/TopListPanel';

// Icons
import { buildCurrencyStorageUrl } from 'icons/imports'; // Helpers

// API Endpoints
import { trendsScoresGet } from 'apis/imports';

// Utils
import { getAssetBase } from 'utils/assets-bases';
import {
    getAgreedCookiesPolicy, getSelectedCurrency, getSelectedExchange, getSelectedTimeframe, getLanguage, getTop100Filter,
    getTheme, setAgreedCookiesPolicy
} from 'utils/cookies';
import { getUserConnectedExchanges } from 'utils/exchanges';
import { getTrendsMarketcaps } from 'utils/trends-marketcaps';

import usePriceWatcher from 'hooks/usePriceWatcher';

// PDF
import Pdf from '../../documents/cookies-policy/cookies-policy.pdf';


// Styles
import 'react-loading-skeleton/dist/skeleton.css';
import './Overview.css';
import './OverviewBrowser.css';
import './OverviewMobile.css';


const Overview = () => {

    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'overview';

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { setIsOpen, setSteps, setCurrentStep } = useTour();

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);

    const [appLang,] = useState(getLanguage());
    const [appTheme,] = useState(getTheme());
    const [appSelectedExchange, setAppSelectedExchange] = useState(getSelectedExchange());
    const [appSelectedCurrency, setAppSelectedCurrency] = useState(getSelectedCurrency());
    const [appSelectedTimeframe, setAppSelectedTimeframe] = useState(getSelectedTimeframe());
    const [appTop100Filter, setAppTop100Filter] = useState(getTop100Filter());

    const [changingFilters, setChangingFilters] = useState(false);

    const [popUpDuration,] = useState(3000);
    const [popUpLevel,] = useState('warning');
    const [popUpText,] = useState('-');

    const [userExchanges, setUserExchanges] = useState(getUserConnectedExchanges({}));
    const [tickers, setTickers] = useState({});

    const [tickerTapeCoins, setTickerTapeCoins] = useState([]);

    const [topGainers, seTopGainers] = useState([]);
    const [topLoosers, setTopLoosers] = useState([]);
    const [topScores, setTopScores] = useState([]);

    const [loadingTickers, setLoadingTickers] = useState(true);
    const [loadingTopScores, setLoadingTopScores] = useState(true);


    usePriceWatcher(isUserAuthenticated, userExchanges, appSelectedCurrency, appSelectedTimeframe, setTickers);


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('overview')} - Smart Trade`;
        }
    }, [location.pathname]);


    $('.reactour__close-button').on('click', function () {
        document.getElementById('toolbar-container').classList.remove('stepping');
    });


    $('.reactour__mask').on('click', function () {
        document.getElementById('toolbar-container').classList.remove('stepping');
    });


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {
        if (!changingFilters) setChangingFilters(true);
        getScores();
    }, [appTop100Filter, appSelectedExchange, appSelectedCurrency, appSelectedTimeframe]);


    useEffect(() => {

        if (!loadingTickers) setLoadingTickers(changingFilters);

    }, [changingFilters]);


    useEffect(() => {
        if (topScores.length == 0 || tickerTapeCoins.length == 0) return;
        setLoadingTopScores(false);
    }, [topScores, tickerTapeCoins]);


    useEffect(() => {

        if (Object.keys(tickers).length === 0) return;

        setLoadingTickers(false);

        if (!(appSelectedExchange in tickers)) return;

        let trendsMarketcaps = {};

        if (appTop100Filter) {
            trendsMarketcaps = getTrendsMarketcaps({ asObject: true });
        }

        let tickerList = Object.entries(tickers[appSelectedExchange]).reduce((tickers, [pair, ticker]) => {
            const [base, _] = pair.split('/');

            if (base.includes('USD')) return tickers;

            ticker.symbol = base;

            if (appTop100Filter && trendsMarketcaps) {
                let baseAsset = getAssetBase({ symbol: base });
                if (!(baseAsset.slug in trendsMarketcaps)) return tickers;
            }

            ticker.pnl = ticker.pnls[appSelectedTimeframe][1];

            tickers.push(ticker);

            return tickers;
        }, []);

        if (!tickerList.length) return;

        getTickerTopList(topGainers, seTopGainers, tickerList, 'pnl', 'desc', 3);
        getTickerTopList(topLoosers, setTopLoosers, tickerList, 'pnl', 'asc', 3);

        let exchangeTickers = tickers[appSelectedExchange];

        let updateTickerTapeCoins = tickerTapeCoins.map((ticker) => {

            if (!(ticker.pair in exchangeTickers)) return ticker;

            ticker.price = exchangeTickers[ticker.pair].price;
            ticker.pnl = exchangeTickers[ticker.pair].pnls[appSelectedTimeframe][1];

            return ticker;
        });

        setTickerTapeCoins(updateTickerTapeCoins);

        let updateTopScores = topScores.map((ticker) => {

            if (!(ticker.pair in exchangeTickers)) return ticker;

            ticker.price = exchangeTickers[ticker.pair].price;
            ticker.pnl = exchangeTickers[ticker.pair].pnls[appSelectedTimeframe][1];

            return ticker;
        });

        setTopScores(updateTopScores);
    }, [tickers]);


    const getTickerTopList = (getter, setter, list, sortBy, sortDirection, limit) => {
        let getterAux = [...getter];
        let listAux = [...list];

        if (changingFilters) getterAux = [];
        setChangingFilters(false);

        // Lógica para manter valores que por algum motivo não são retornados
        // em alguns casos durante o loop de atualização
        getterAux.forEach((item) => {
            let { base } = item;
            let found = listAux.find((item) => item.base === base);
            if (!found) {
                listAux.push(item);
            }
        });

        if (sortDirection === 'asc') listAux.sort((a, b) => a[sortBy] - b[sortBy]);
        else listAux.sort((a, b) => b[sortBy] - a[sortBy]);

        listAux = listAux.slice(0, limit);
        listAux = listAux.map((ticker) => {
            const { symbol } = ticker;

            const assetBase = getAssetBase({ symbol });

            return {
                icon: buildCurrencyStorageUrl(assetBase.slug),
                name: assetBase.name,
                ...ticker
            };
        });

        setter(listAux);
    };


    const getScores = async () => {

        const result = await trendsScoresGet({ exchange_slug: appSelectedExchange, timeframe: appSelectedTimeframe });

        if (result.isSuccess) {
            let scores = Object.entries(result.response.items).reduce((scores, [pair, score]) => {
                const symbol = pair.split('/')[0];

                if (symbol.includes('USD')) return scores;

                const base = getAssetBase({ symbol });

                let ticker = {
                    pair,
                    symbol,
                    name: base.name,
                    icon: buildCurrencyStorageUrl(base.slug),
                    price: 0,
                    pnl: 0,
                    score: score
                };

                scores.push(ticker);

                return scores;
            }, []);

            scores = scores.sort((a, b) => b.score - a.score);

            setTickerTapeCoins(scores);
            setTopScores(scores);
        }
    };


    const handleIAgreeButton = (event) => {
        var option = event.target.id.replace('-btn', '');
        if (option === 'yes') setAgreedCookiesPolicy();
    };


    const handleLearnMoreButton = (event) => {
        var option = event.target.id.replace('-btn', '');
        if (option === 'no') window.open(Pdf);
    };


    return (
        <Page id={pageId} >
            <Loading id={'overview'} />

            <CookiesPolicyModal
                handle={handleIAgreeButton}
                handleClickOut={handleLearnMoreButton}
                showDialog={!getAgreedCookiesPolicy()}
                text={t('cookies.messages.content')}
            />

            <MessagePopup
                level={popUpLevel}
                text={popUpText}
                duration={popUpDuration}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='full'
                setIsUserAuthenticated={setIsUserAuthenticated}
                setTop100Filter={setAppTop100Filter}
                setSelectedExchange={setAppSelectedExchange}
                setSelectedCurrency={setAppSelectedCurrency}
                setSelectedTimeframe={setAppSelectedTimeframe}
                setUserExchanges={setUserExchanges}
                showToolbar={true}
                showHeader={true}
                tickerTapeCoins={tickerTapeCoins.filter((ticker) => ticker.price > 0).slice(0, 10)}
            >
                <Row
                    a='unset'
                    className='main-row'
                    fill='width'
                >
                    {/* First Column */}
                    <Column w='5'>
                        <BalancesPanel
                            isUserAuthenticated={isUserAuthenticated}
                            pageId={pageId}
                            tickers={tickers}
                            exchanges={userExchanges}
                            selectedExchange={appSelectedExchange}
                            selectedCurrency={appSelectedCurrency}
                            selectedTimeframe={appSelectedTimeframe}
                        />
                    </Column>

                    {/* Second Column */}
                    <Column w='14'>
                        <Row id='top-panels'
                            fill='width'
                        >
                            <TopListPanel
                                id='top-gainers'
                                isUserAuthenticated={isUserAuthenticated}
                                list={topGainers}
                                loadingTickers={loadingTickers}
                                selectedExchange={appSelectedExchange}
                                selectedTimeframe={appSelectedTimeframe}
                                title='top-gainers'
                            />

                            <TopListPanel
                                id='top-loosers'
                                isUserAuthenticated={isUserAuthenticated}
                                list={topLoosers}
                                loadingTickers={loadingTickers}
                                selectedExchange={appSelectedExchange}
                                selectedTimeframe={appSelectedTimeframe}
                                title='top-loosers'
                            />

                            <TopListPanel
                                id='top-bnx-score'
                                isUserAuthenticated={isUserAuthenticated}
                                list={topScores.filter((ticker) => ticker.price > 0).slice(0, 3)}
                                loadingTickers={loadingTopScores || loadingTickers}
                                selectedExchange={appSelectedExchange}
                                selectedTimeframe={appSelectedTimeframe}
                                title='top-bnx-score'
                            />
                        </Row>

                        <TradesPanel
                            appLang={appLang}
                            selectedExchange={appSelectedExchange}
                            selectedTimeframe={appSelectedTimeframe}
                            exchanges={userExchanges}
                            isUserAuthenticated={isUserAuthenticated}
                            pageId={pageId}
                            tickers={tickers}
                        />
                    </Column>

                    <Column w='5'>
                        <ArticlesPanel
                            appLang={appLang}
                            isUserAuthenticated={isUserAuthenticated}
                            pageId={pageId}
                        />
                    </Column>
                </Row>
            </Layout>
        </Page >
    );
};

export default Overview;
