import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import deDE from './de-DE.json';
import enUS from './en-US.json';
import esES from './es-ES.json';
import frFR from './fr-FR.json';
import huHU from './hu-HU.json';
import inIN from './hi-IN.json';
import itIT from './it-IT.json';
import jaJP from './ja-JP.json';
import koKR from './ko-KR.json';
import poPL from './po-PL.json';
import ptBR from './pt-BR.json';
import ruRU from './ru-RU.json';


const resources = {
    'de-DE': {
        translation: deDE,
    },
    'en-US': {
        translation: enUS,
    },
    'es-ES': {
        translation: esES,
    },
    'fr-FR': {
        translation: frFR,
    },
    'hu-HU': {
        translation: huHU,
    },
    'hi-IN': {
        translation: inIN,
    },
    'it-IT': {
        translation: itIT,
    },
    'ja-JP': {
        translation: jaJP,
    },
    'ko-KR': {
        translation: koKR,
    },
    'po-PL': {
        translation: poPL,
    },
    'pt-BR': {
        translation: ptBR,
    },
    'ru-RU': {
        translation: ruRU,
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
