
import { getGlobalAssetsCurrencies } from './assets';

// Nota: caso houver slugs com um mesmo symbol, assume-se que serão em corretoras diferentes,
// então se caso for necessário, informe o exchange_slug e filtre as exceções
export const getAssetCurrency = ({ symbol = null, name = null }) => {
    const currencies = getGlobalAssetsCurrencies({});
    let currency = { symbol, name, type: null };

    if (currencies) {
        if (symbol) {
            symbol = symbol.toUpperCase();
            currency = currencies.filter(currency => currency.symbol === symbol);
        }
        else if (name) {
            name = name.toUpperCase();
            currency = currencies.filter(currency => currency.name === name);
        }
    }

    if (currency.length > 0) return currency[0];

    return symbol;
};