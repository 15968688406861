/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import i18n from 'locales/i18n';
import { useTranslation } from 'react-i18next';

import { Select, DatePicker, Checkbox, Tooltip } from 'antd';

import Skeleton from 'react-loading-skeleton';

import {
    Page, Layout, Loading, MessagePopup, Row, Column, Panel,
    SwitchableIcon, Title, Label, Badge, Card, Date as DateFormatter,
    Button, Link
} from 'components/imports';

import { InfoSvg, RefreshSvg, ConfirmSvg, CloseSvg, WarningSvg } from 'icons/imports';

import { timeframes } from 'constants';

import { getGlobalExchanges, getUserConnectedExchanges } from 'utils/exchanges';
import { getUserAtsPairs } from 'utils/user-ats-pairs';

import { getLanguage, getTheme } from 'utils/cookies';
import { currencyFormatter, percentageFormatter } from 'utils/formatters';

import { atsReportsExecutionsMeGet, atsReportsExecutionsSingleGet, ordersMeSingleGet } from 'apis/imports';

import './AtsReports.css';


const AtsReports = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'ats-reports';

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);

    const [appLang] = useState(getLanguage());
    const [appTheme] = useState(getTheme());

    const [popUpDuration,] = useState(3000);
    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');

    const [exchanges,] = useState(getGlobalExchanges({}));
    const [userExchanges, setUserExchanges] = useState(getUserConnectedExchanges({}));
    const [userAtsPairs,] = useState(getUserAtsPairs({}));

    const [isApplyEnabled, setIsApplyEnabled] = useState(true);
    const [isLoadingExecutions, setIsLoadingExecutions] = useState(true);
    const [isLoadingExecutionDetails, setIsLoadingExecutionDetails] = useState(true);

    const [newSearch, setNewSearch] = useState(true);
    const [executionsListData, setExecutionsListData] = useState([]);
    const [selectedExecution, setSelectedExecution] = useState(null);
    const [executionDetailsData, setExecutionDetailsData] = useState({});

    const [loadedChunksCount, setLoadedChunksCount] = useState(0);
    const [canLoadMore, setCanLoadMore] = useState(true);
    const [totalExecutions, setTotalExecutions] = useState(0);

    const { RangePicker } = DatePicker;

    const [executionListExchangesTooltip, setExecutionListExchangesTooltip] = useState();
    const [executionListPairsTooltip, setExecutionListPairsTooltip] = useState();
    const [executionListTimeframesTooltip, setExecutionListTimeframesTooltip] = useState();

    const [executionExchangesFilter, setExecutionExchangesFilter] = useState([]);
    const [executionPairsFilter, setExecutionPairsFilter] = useState([]);
    const [executionTimeframesFilter, setExecutionTimeframesFilter] = useState([]);

    const [executionsListExchangesFilter, setExecutionsListExchangesFilter] = useState([]);
    const [executionsListPairsFilter, setExecutionsListPairsFilter] = useState([]);
    const [executionsListTimeframesFilter, setExecutionsListTimeframesFilter] = useState([]);
    const [executionsListHasSignalOnly, setExecutionsListHasSignalOnly] = useState(false);
    const [executionsListDateRangeFilter, setExecutionsListDateRangeFilter] = useState([]);

    const infoSvg = <InfoSvg className={'icon-svg info'} />;
    const confirmSvg = <ConfirmSvg className={'icon-svg confirm'} />;
    const closeSvg = <CloseSvg className={'icon-svg close'} />;
    const warningSvg = <WarningSvg className={'icon-svg warning'} />;
    const refreshExecutionsDetailsIcon = <RefreshSvg className={'icon-svg refresh' + (isLoadingExecutionDetails ? ' spinning' : '')} />;


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('ats-reports')} - Smart Trade`;
            getExecutions();
        }
    }, [location.pathname]);


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {
        if (executionsListData.length > 0 && !selectedExecution)
            setSelectedExecution(executionsListData[0]);
    }, [executionsListData]);


    useEffect(() => {
        setExecutionDetailsData({});
        if (selectedExecution?.id) getExecutionDetails();
    }, [selectedExecution]);


    useEffect(() => {
        if (newSearch) getExecutions();
    }, [loadedChunksCount, newSearch]);


    // useEffect(() => {
    //     console.log(executionDetailsData);
    // }, [executionDetailsData]);

    useEffect(() => {

        const hasExchanges = executionsListExchangesFilter.length > 0;
        const hasPairs = executionsListPairsFilter.length > 0;
        const hasTimeframes = executionsListTimeframesFilter.length > 0;

        setExecutionListTimeframesTooltip(null);
        setExecutionListPairsTooltip(null);
        setExecutionListExchangesTooltip(null);

        if (hasExchanges && hasPairs && !hasTimeframes) {
            setIsApplyEnabled(false);
            setExecutionListTimeframesTooltip(t('select-timeframe-required'));
        }
        else if (hasExchanges && !hasPairs && hasTimeframes) {
            setIsApplyEnabled(false);
            setExecutionListPairsTooltip(t('select-pair-required'));
        }
        else if (!hasExchanges && hasPairs && hasTimeframes) {
            setIsApplyEnabled(false);
            setExecutionListExchangesTooltip(t('select-exchange-required'));
        }
        else
            setIsApplyEnabled(true);
    }, [executionsListExchangesFilter, executionsListPairsFilter, executionsListTimeframesFilter]);


    const getExecutions = async () => {

        if (!isUserAuthenticated) return;

        const limit = 10;

        setNewSearch(false);
        setIsLoadingExecutions(true);

        if (executionsListExchangesFilter.length > 0) setExecutionExchangesFilter(executionsListExchangesFilter);
        if (executionsListPairsFilter.length > 0) setExecutionPairsFilter(executionsListPairsFilter);
        if (executionsListTimeframesFilter.length > 0) setExecutionTimeframesFilter(executionsListTimeframesFilter);

        let [since, until] = executionsListDateRangeFilter;

        let args = {
            exchanges: executionsListExchangesFilter,
            pairs: executionsListPairsFilter,
            timeframes: executionsListTimeframesFilter,
            hasSignalsOnly: executionsListHasSignalOnly
        };

        args.limit = limit;
        args.offset = limit * loadedChunksCount;

        if (executionsListDateRangeFilter[0])
            args.since = since.valueOf();

        if (executionsListDateRangeFilter[1])
            args.until = until.valueOf();

        try {
            const result = await atsReportsExecutionsMeGet(args);

            if (result.isSuccess) {
                const response = result.response.items;
                setTotalExecutions(result.response.total);

                setCanLoadMore(response.length == limit);

                if (loadedChunksCount > 0)
                    setExecutionsListData([...executionsListData, ...response]);
                else
                    setExecutionsListData(response);
                setIsLoadingExecutions(false);
            }
        } catch (error) {
            setPopUpLevel('error');
            setPopUpText(t('error.network'));
        } finally {
            setIsLoadingExecutions(false);
        }
    };


    const getExecutionDetails = async () => {
        if (!isUserAuthenticated) return;

        let args = {
            executionId: selectedExecution?.id
        };

        if (!selectedExecution.id) return;

        setIsLoadingExecutionDetails(true);

        try {
            const result = await atsReportsExecutionsSingleGet(args);

            if (result.isSuccess) {
                let response = result.response;

                const negotiations = response.negotiations
                    .map((item, index) => {
                        let signal = response.signals.find(signal =>
                            signal.exchange_slug === item.exchange_slug &&
                            signal.pair === item.pair &&
                            signal.timeframe === item.timeframe
                        );

                        let order = {};
                        if (signal) {
                            order = response.orders.find(order => order.signal_id === signal.id);
                        }

                        if ('order_result' in order) {
                            order.result = order.order_result;
                        }

                        item.exchangeOrder = null;

                        if (order?.result?.order_id) item.exchangeOrder = 'loading';

                        item.index = index;
                        item.signal = signal;
                        item.order = order;
                        item.exchangeSlug = item.exchange_slug;
                        delete item.exchange_slug;

                        return item;
                    });

                // Agora, carregar as exchangeOrders em paralelo
                fetchExchangeOrders(negotiations);

                // Definir os dados iniciais sem as exchangeOrders
                setExecutionDetailsData({ ...response, negotiations });
                setIsLoadingExecutionDetails(false);
            }
        } catch (error) {
            console.error(error);
            setPopUpLevel('error');
            setPopUpText(t('error.network'));
        } finally {
            setIsLoadingExecutions(false);
        }
    };

    const fetchExchangeOrders = async (negotiations) => {

        let negotiationsWithOrder = negotiations.reduce((negotiations, negotiation, index) => {
            if (negotiation?.order?.result?.order_id) {
                negotiations.push({
                    index,
                    orderId: negotiation.order.result.order_id,
                    timestamp: negotiation.timestamp,
                    signal: negotiation.signal
                });
            }
            return negotiations;
        }, []);

        // Mapear para Promises de fetch das exchangeOrders
        const fetchPromises = negotiationsWithOrder.map(async item => {
            try {
                const result = await ordersMeSingleGet({ orderId: item.orderId });
                const { isSuccess, response } = result;

                if (!isSuccess) {
                    return { index: item.index, exchangeOrder: null };
                }

                if (response && item?.signal) {
                    response.timedelta = (response.timestamp - item.signal.timestamp) / 1000;
                    response.slippage = (response.price - item.signal.price) / item.signal.price;
                }

                return {
                    index: item.index,
                    timestamp: item.timestamp,
                    exchangeOrder: response
                };
            } catch (error) {
                return { index: item.index, exchangeOrder: null };
            }
        });

        // Executar todas as Promises em paralelo
        const results = await Promise.all(fetchPromises);

        results.map(result => {
            let { index, timestamp, exchangeOrder } = result;

            // Verificar se o index é válido e evitar que uma ordem seja atribuìda na execução errada
            if (!negotiations[index]) return;
            if (negotiations[index].timestamp != timestamp) return;

            negotiations[index].exchangeOrder = exchangeOrder;
        });

        executionDetailsData.negotiations = negotiations;
        setExecutionDetailsData(executionDetailsData);
    };


    const buildExecutionExchangesFilter = () => {
        if (!(selectedExecution?.exchanges)) return [];

        return selectedExecution.exchanges.map((exchangeSlug) => {
            let exchange = exchanges.find(exc => exc.slug == exchangeSlug);

            if (!exchange) return null;
            return {
                label: exchange.name,
                value: exchange.slug
            };
        });
    };


    const buildExecutionPairsFilter = () => {
        if (!(selectedExecution?.pairs)) return [];

        return selectedExecution.pairs.map((pair) => ({
            label: pair,
            value: pair
        }));
    };


    const buildExecutionTimeframesFilter = () => {
        if (!(selectedExecution?.timeframes)) return [];

        return selectedExecution.timeframes.map((timeframe) => ({
            label: timeframe,
            value: timeframe
        }));
    };


    const buildExecutionsListExchangesFilter = () =>
        userExchanges.map((exchange) => ({
            label: exchange.name,
            value: exchange.slug,
        }));


    const buildExecutionsListPairsFilter = () => {
        let pairs = userAtsPairs.map((pair) => pair.split(':')[1]);

        if (pairs.length == 0) return [];

        // Remover items repetidos da lista
        pairs = [...new Set(pairs)];

        return pairs.map((pair) => ({
            label: pair,
            value: pair,
        }));
    };


    const buildExecutionsListTimeframesFilter = () =>
        timeframes.map((timeframe) => ({
            label: timeframe,
            value: timeframe,
        }));


    return (
        <Page id={pageId}>
            <Loading id={'overview'} />

            <MessagePopup
                level={popUpLevel}
                text={popUpText}
                duration={popUpDuration}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='basic'
                setIsUserAuthenticated={setIsUserAuthenticated}
                setUserExchanges={setUserExchanges}
                showToolbar={true}
                showHeader={true}
            >
                <Row
                    className='main-row'
                    fill='all'
                >
                    <Panel id='executions-list'
                        fill='all'
                        w='8'
                    >
                        <Row
                            fill='width'
                            j='between'
                        >
                            <Title id='execution-list-title'
                                txt={t('execution.p')}
                                variation='secondary'
                            />

                            <Button
                                id='export-executions-button'
                                variation='secondary'
                                state='disabled'
                            >
                                {t('export')}
                            </Button>
                        </Row>

                        <hr />

                        <p><b>{t('executions-filters')}</b></p>

                        <Row id='executions-filters'
                            fill='width'
                        >
                            <Tooltip
                                placement="top"
                                title={executionListExchangesTooltip}
                                align={'center'}
                                open={executionListExchangesTooltip}
                            >
                                <Select
                                    className='w-6'
                                    mode='tags'
                                    maxCount={3}
                                    placeholder={t('exchange.p')}
                                    variant='borderless'
                                    options={buildExecutionsListExchangesFilter()}
                                    onChange={(value) => setExecutionsListExchangesFilter(value)}
                                />
                            </Tooltip>

                            <Tooltip
                                placement="top"
                                title={executionListPairsTooltip}
                                align={'center'}
                                open={executionListPairsTooltip}
                            >
                                <Select
                                    className='w-6'
                                    mode='tags'
                                    maxCount={3}
                                    placeholder={t('pair.p')}
                                    variant='borderless'
                                    options={buildExecutionsListPairsFilter()}
                                    onChange={(value) => setExecutionsListPairsFilter(value)}
                                />
                            </Tooltip>

                            <Tooltip
                                placement="top"
                                title={executionListTimeframesTooltip}
                                align={'center'}
                                open={executionListTimeframesTooltip}
                            >
                                <Select
                                    className='w-6'
                                    mode='tags'
                                    maxCount={3}
                                    placeholder={t('timeframe.p')}
                                    variant='borderless'
                                    options={buildExecutionsListTimeframesFilter()}
                                    onChange={(value) => setExecutionsListTimeframesFilter(value)}
                                />
                            </Tooltip>

                            <Checkbox
                                className='w-6'
                                checked={executionsListHasSignalOnly}
                                onChange={() => setExecutionsListHasSignalOnly(!executionsListHasSignalOnly)}
                            >
                                {t('with-signal-only')}
                            </Checkbox>
                        </Row>

                        <Row
                            fill='width'
                        >
                            <RangePicker
                                className='w-18'
                                showTime={{ format: 'HH:mm' }}
                                variant='borderless'
                                onChange={(value) => setExecutionsListDateRangeFilter(value)}
                                format="YYYY-MM-DD HH:mm"
                                defaultValue={executionsListDateRangeFilter}
                            />

                            <Button
                                className='w-6'
                                variation='primary'
                                onClick={() => {
                                    setLoadedChunksCount(0);
                                    setNewSearch(true);
                                }}
                                state={isApplyEnabled ? (isLoadingExecutions ? 'loading' : 'enabled') : 'disabled'}
                            >
                                {t('apply')}
                            </Button>
                        </Row>

                        <hr />

                        <Row
                            id='execution-list-header'
                            fill='width'
                            j='between'
                            style={{ paddingRight: 'var(--padding-primary)' }}
                        >
                            <p className='col-0'>
                                <Column g='0'>
                                    <b>{t('negotiation.p')}</b>

                                    <b>{t('date.s')}</b>
                                </Column>
                            </p>
                            <p className='col-1'><b>{t('signal.p')}</b></p>
                            <p className='col-2'><b>{t('order.p')}</b></p>
                        </Row>

                        <Column
                            id='executions-results-list'
                            fill='width'
                            fit='height'
                            g='0'
                            scroll
                        >
                            {
                                executionsListData.map((item, index) => {
                                    return (
                                        <>
                                            <hr />
                                            <Column
                                                className={`execution-list-item ${selectedExecution?.id === item.id ? 'selected' : ''}`}
                                                fill='width'
                                                key={index}
                                                onClick={() => {
                                                    setSelectedExecution(item);
                                                    setExecutionDetailsData({}); // Limpa os detalhes anteriores
                                                }}
                                            >
                                                <Row fill='width'>
                                                    {item?.appliers?.length > 0 && <p><b>{item.appliers[0].replaceAll(':', ' : ')}</b></p>}

                                                    {item?.appliers?.length > 1 &&
                                                        <Badge>
                                                            <p>+{item.appliers.length - 1}</p>

                                                            {/* <Popover
                                                                content={(<>{item.appliers.map((applier, index) => <Badge fill key={index}><p>{applier.replaceAll(':', ' : ')}</p></Badge>)}</>)}
                                                                title={t('negotiation.p')}
                                                                trigger='click'
                                                            >
                                                                <p>+{item.appliers.length - 1}</p>
                                                            </Popover> */}
                                                        </Badge>
                                                    }
                                                </Row>

                                                <Row
                                                    fill='width'
                                                    j='between'
                                                    key={index}
                                                >
                                                    <Row className='col-0'>
                                                        <DateFormatter
                                                            timestamp={new Date(item.created_at).getTime()}
                                                            seconds
                                                        />
                                                    </Row>

                                                    <Row className='col-1' j='center'>
                                                        {item?.signals?.count?.error > 0 ? warningSvg : <p>{item?.signals?.count?.success}</p>}
                                                    </Row>

                                                    <Row className='col-2'>
                                                        {item?.orders?.count &&
                                                            <>
                                                                {item.orders?.count?.success > 0 &&
                                                                    <Badge
                                                                        variation='success'
                                                                        fill
                                                                    >
                                                                        {confirmSvg}

                                                                        {item.orders?.count?.success}
                                                                    </Badge>
                                                                }

                                                                {item.orders?.count?.warning > 0 &&
                                                                    <Badge
                                                                        variation='warning'
                                                                        fill
                                                                    >
                                                                        {warningSvg}

                                                                        {item.orders?.count.warning}
                                                                    </Badge>
                                                                }

                                                                {item.orders?.count?.error > 0 &&
                                                                    <Badge
                                                                        variation='error'
                                                                        fill
                                                                    >
                                                                        {closeSvg}

                                                                        {item.orders?.count.error}
                                                                    </Badge>
                                                                }
                                                            </>
                                                        }
                                                    </Row>
                                                </Row>
                                            </Column>
                                        </>
                                    );
                                })
                            }
                        </Column >

                        {
                            executionsListData.length === 0 && !isLoadingExecutions ?
                                <Row
                                    fill='width'
                                    j='center'
                                >
                                    <p>{t('no-results')}</p>
                                </Row>
                                :
                                canLoadMore && !isLoadingExecutions &&
                                <Row
                                    fill='width'
                                    j='center'
                                >
                                    <Link onClick={() => {
                                        setLoadedChunksCount(loadedChunksCount + 1);
                                        setNewSearch(true);
                                    }}>{t('show.more')}</Link>
                                </Row>
                        }

                        <Row>
                            <p>
                                {
                                    t('showing-x-of-y')
                                        .replace('{x}', executionsListData.length)
                                        .replace('{y}', totalExecutions)
                                }
                            </p>
                        </Row>
                    </Panel>

                    <Panel id='execution-details'
                        fill='all'
                        w='16'
                    >
                        <Row
                            fill='width'
                            j='between'
                        >
                            <Row>
                                <Title id='execution-details-title'
                                    txt={t('execution-details')}
                                    variation='secondary'
                                />

                                {selectedExecution?.created_at &&
                                    <DateFormatter
                                        className='execution-details-date'
                                        timestamp={new Date(selectedExecution.created_at).getTime()}
                                        seconds
                                    />
                                }
                            </Row>

                            <SwitchableIcon
                                id='refresh-execution-details'
                                onToggle={getExecutionDetails}
                                staticImage={refreshExecutionsDetailsIcon}
                            />
                        </Row>

                        <hr />

                        <Row a='start' fill='width'>
                            <Column w='6'>
                                <p>{t('overview')}</p>

                                <Column>
                                    <Row>
                                        <p className='side-label'>{t('signal.p')}</p>

                                        {selectedExecution?.signals?.count?.error > 0
                                            ? warningSvg
                                            : <p>{selectedExecution?.signals?.count?.success}</p>}
                                    </Row>

                                    <Row>
                                        <p className='side-label'>{t('order.p')}</p>

                                        {selectedExecution?.orders?.count ?
                                            <>
                                                {selectedExecution.orders?.count?.success > 0 &&
                                                    <Badge variation='success'>
                                                        {confirmSvg}

                                                        {selectedExecution.orders?.count?.success}

                                                        <Tooltip
                                                            placement="top"
                                                            title={t('success-order.p')}
                                                            align={'center'}
                                                        >
                                                            {infoSvg}
                                                        </Tooltip>
                                                    </Badge>
                                                }

                                                {selectedExecution.orders?.count?.warning > 0 &&
                                                    <Badge variation='warning'>
                                                        {warningSvg}

                                                        {selectedExecution.orders?.count?.warning}

                                                        <Tooltip
                                                            placement="top"
                                                            title={t('warning-order.p')}
                                                            align={'center'}
                                                        >
                                                            {infoSvg}
                                                        </Tooltip>
                                                    </Badge>
                                                }

                                                {selectedExecution.orders?.count?.error > 0 &&
                                                    <Badge variation='error'>
                                                        {closeSvg}

                                                        {selectedExecution.orders?.count?.error}

                                                        <Tooltip
                                                            placement="top"
                                                            title={t('error-order.p')}
                                                            align={'center'}
                                                        >
                                                            {infoSvg}
                                                        </Tooltip>
                                                    </Badge>
                                                }
                                            </>
                                            :
                                            <b><p>{t('no-order')}</p></b>
                                        }
                                    </Row>
                                </Column>
                            </Column>

                            <Column w='4'>
                                <p>{t('exchange.p')}</p>

                                <Row fill='width' flex='wrap'>
                                    {selectedExecution?.exchanges &&
                                        selectedExecution.exchanges.map((exchangeSlug, index) => (
                                            <Badge key={exchangeSlug + '-' + index}>
                                                {(exchanges.find(exc => exc.slug == exchangeSlug))?.name ?? exchangeSlug}
                                            </Badge>
                                        ))
                                    }
                                </Row>
                            </Column>

                            <Column w='10'>
                                <p>{t('pair.p')}</p>

                                <Row fill='width' flex='wrap'>
                                    {selectedExecution?.pairs &&
                                        selectedExecution.pairs.map((pair, index) => (
                                            <Badge key={pair + '-' + index}>
                                                {pair}
                                            </Badge>
                                        ))
                                    }
                                </Row>
                            </Column>

                            <Column w='4'>
                                <p>{t('timeframe.p')}</p>

                                <Row fill='width' flex='wrap'>
                                    {selectedExecution?.timeframes &&
                                        selectedExecution.timeframes.map((timeframe, index) => (
                                            <Badge key={timeframe + '-' + index}>
                                                {t(timeframe)}
                                            </Badge>
                                        ))
                                    }
                                </Row>
                            </Column>
                        </Row>

                        <hr />

                        <>
                            <Row
                                fill='width'
                                j='between'
                            >
                                <Column>
                                    <Title
                                        variation='tertiary'
                                        txt={t('negotiation.p')}
                                    />

                                    {isLoadingExecutionDetails ?
                                        <Skeleton
                                            className='skeleton'
                                            width='8rem'
                                            height='1.5rem'
                                        />
                                        :
                                        <p>{
                                            t('showing-x-of-y')
                                                .replace('{x}', (executionDetailsData?.negotiations ?? []).filter((negotiation) => {
                                                    let { exchangeSlug, pair, timeframe } = negotiation;

                                                    if (executionExchangesFilter.length > 0 && !executionExchangesFilter.includes(exchangeSlug)) return null;
                                                    if (executionPairsFilter.length > 0 && !executionPairsFilter.includes(pair)) return null;
                                                    if (executionTimeframesFilter.length > 0 && !executionTimeframesFilter.includes(timeframe)) return null;

                                                    return true;
                                                }).length)
                                                .replace('{y}', executionDetailsData?.negotiations?.length)
                                        }</p>
                                    }
                                </Column>

                                <Column w='16'>
                                    <p><b>{t('negotiations-filters')}</b></p>

                                    <Row id='negotiations-filters'
                                        fill='width'>
                                        <Select
                                            className='w-8'
                                            mode='tags'
                                            placeholder={t('exchange.p')}
                                            variant='borderless'
                                            value={executionExchangesFilter}
                                            options={buildExecutionExchangesFilter()}
                                            onChange={(value) => setExecutionExchangesFilter(value)}
                                        />

                                        <Select
                                            className='w-8'
                                            mode='tags'
                                            placeholder={t('pair.p')}
                                            variant='borderless'
                                            value={executionPairsFilter}
                                            options={buildExecutionPairsFilter()}
                                            onChange={(value) => setExecutionPairsFilter(value)}
                                        />

                                        <Select
                                            className='w-8'
                                            mode='tags'
                                            placeholder={t('timeframe.p')}
                                            variant='borderless'
                                            value={executionTimeframesFilter}
                                            options={buildExecutionTimeframesFilter()}
                                            onChange={(value) => setExecutionTimeframesFilter(value)}
                                        />
                                    </Row>

                                </Column>
                            </Row>

                            {isLoadingExecutionDetails ?
                                <>
                                    <hr />

                                    <Column
                                        fill='width'
                                        key={'loading-skeleton-0'}
                                    >
                                        <Row
                                            a='start'
                                            fill='width'
                                        >
                                            <Skeleton
                                                className='skeleton'
                                                width='20rem'
                                                height='2.25rem'
                                            />

                                            <Column>
                                                <Skeleton
                                                    className='skeleton'
                                                    width='5rem'
                                                    height='1.25rem'
                                                />

                                                <Skeleton
                                                    className='skeleton'
                                                    width='10rem'
                                                    height='1.25rem'
                                                />

                                                <Skeleton
                                                    className='skeleton'
                                                    width='8rem'
                                                    height='1rem'
                                                />
                                            </Column>

                                            <Column>
                                                <Skeleton
                                                    className='skeleton'
                                                    width='5rem'
                                                    height='1.25rem'
                                                />

                                                <Skeleton
                                                    className='skeleton'
                                                    width='10rem'
                                                    height='1.25rem'
                                                />

                                                <Skeleton
                                                    className='skeleton'
                                                    width='8rem'
                                                    height='1rem'
                                                />
                                            </Column>

                                            <Row a='end'>
                                                <Column>
                                                    <Skeleton
                                                        className='skeleton'
                                                        width='5rem'
                                                        height='1.25rem'
                                                    />

                                                    <Skeleton
                                                        className='skeleton'
                                                        width='10rem'
                                                        height='1.25rem'
                                                    />

                                                    <Skeleton
                                                        className='skeleton'
                                                        width='8rem'
                                                        height='1rem'
                                                    />

                                                    <Skeleton
                                                        className='skeleton'
                                                        width='8rem'
                                                        height='1rem'
                                                    />
                                                </Column>

                                                <Column>
                                                    <Skeleton
                                                        className='skeleton'
                                                        width='10rem'
                                                        height='1.25rem'
                                                    />

                                                    <Skeleton
                                                        className='skeleton'
                                                        width='8rem'
                                                        height='1rem'
                                                    />

                                                    <Skeleton
                                                        className='skeleton'
                                                        width='8rem'
                                                        height='1rem'
                                                    />
                                                </Column>
                                            </Row>
                                        </Row>

                                        <Skeleton
                                            className='skeleton'
                                            width='10rem'
                                            height='1.5rem'
                                        />

                                        <Row
                                            a='start'
                                            fill='width'
                                            fit='height'
                                        >
                                            <Skeleton
                                                className='skeleton'
                                                width='14rem'
                                                height='16rem'
                                            />

                                            <Skeleton
                                                className='skeleton'
                                                width='14rem'
                                                height='18rem'
                                            />

                                            <Skeleton
                                                className='skeleton'
                                                width='14rem'
                                                height='14rem'
                                            />
                                        </Row>
                                    </Column>
                                </>
                                :
                                <Column
                                    fill='all'
                                    scroll
                                >
                                    {'negotiations' in executionDetailsData
                                        &&
                                        ((executionDetailsData?.negotiations ?? [])
                                            .filter(negotiation => {
                                                let { exchangeSlug, pair, timeframe } = negotiation;
                                                if (executionExchangesFilter.length > 0 && !executionExchangesFilter.includes(exchangeSlug)) return false;
                                                if (executionPairsFilter.length > 0 && !executionPairsFilter.includes(pair)) return false;
                                                if (executionTimeframesFilter.length > 0 && !executionTimeframesFilter.includes(timeframe)) return false;
                                                return true;
                                            })
                                            .sort((a, b) => (b.signal ? 1 : 0) - (a.signal ? 1 : 0))
                                            .map((negotiation, index) => {

                                                let { exchangeSlug, pair, timeframe, signal, exchangeOrder, metadata, error } = negotiation;
                                                let { results } = metadata || { results: {} };

                                                let [baseSymbol, quoteSymbol] = pair.split('/');

                                                if (signal)
                                                    signal.timestamp = new Date(signal.created_at).getTime();

                                                // console.log(exchangeOrder);

                                                return (
                                                    <>
                                                        <hr />

                                                        <Column
                                                            fill='width'
                                                            key={index}
                                                        >
                                                            <Row
                                                                className='negotiation-overview'
                                                                a='start'
                                                                fill='width'
                                                                flex='wrap'
                                                                m='pri-ver'
                                                            >
                                                                <Row>
                                                                    <Label
                                                                        variation='exchange'
                                                                        value={{ exchange: exchanges.find(exchange => exchange.slug === exchangeSlug) }}
                                                                    />

                                                                    <b>:</b>

                                                                    <Label
                                                                        variation='pair-and-name'
                                                                        value={{ pair }}
                                                                    />
                                                                </Row>

                                                                <Column>
                                                                    <p>{t('timeframe.s')}</p>

                                                                    <p><b>{t(timeframe)}</b></p>
                                                                </Column>

                                                                <Column fit='width'>
                                                                    <p>{t('signal.s')}</p>

                                                                    {signal != null &&
                                                                        <Column
                                                                            className='signal-resume'
                                                                        >
                                                                            <Badge variation={signal.side}>
                                                                                <b>{t(signal.side + '-at').replace('{price}', currencyFormatter(signal.price) + ' ' + quoteSymbol)}</b>
                                                                            </Badge>

                                                                            <DateFormatter
                                                                                timestamp={signal.timestamp}
                                                                                seconds
                                                                            />
                                                                        </Column>}

                                                                    {error != null &&
                                                                        <Badge variation='error'>
                                                                            <b>{t('ats.signal-gereneration-error.label')}</b>

                                                                            <Tooltip
                                                                                placement="top"
                                                                                title={t('ats.signal-gereneration-error.desc')}
                                                                                align={'center'}
                                                                            >
                                                                                {infoSvg}
                                                                            </Tooltip>
                                                                        </Badge>}

                                                                    {signal == null &&
                                                                        <p>
                                                                            <b>{t('no-signal')}</b>
                                                                        </p>}
                                                                </Column>

                                                                <Column fit='width'>
                                                                    <p>{t('order.s')}</p>

                                                                    {exchangeOrder ? (
                                                                        exchangeOrder == 'loading' ?
                                                                            <Column>
                                                                                <Skeleton
                                                                                    className='skeleton'
                                                                                    width='10rem'
                                                                                    height='1.25rem'
                                                                                />

                                                                                <Skeleton
                                                                                    className='skeleton'
                                                                                    width='8rem'
                                                                                    height='1rem'
                                                                                />
                                                                            </Column>
                                                                            :
                                                                            <Row>
                                                                                <Column>
                                                                                    <Badge variation={exchangeOrder.side}>
                                                                                        <b>
                                                                                            {
                                                                                                t(exchangeOrder.side + '-amount-at')
                                                                                                    .replace('{amount}', currencyFormatter(exchangeOrder.amount) + ' ' + baseSymbol)
                                                                                                    .replace('{price}', currencyFormatter(exchangeOrder.price) + ' ' + quoteSymbol)
                                                                                            }
                                                                                        </b>
                                                                                    </Badge>

                                                                                    <DateFormatter
                                                                                        timestamp={exchangeOrder.timestamp}
                                                                                        seconds
                                                                                    />
                                                                                </Column>
                                                                            </Row>)
                                                                        : <p>
                                                                            <b>{t('no-order')}</b>
                                                                        </p>
                                                                    }
                                                                </Column>

                                                                <Column fit='width'>
                                                                    <p>{t('detail.p')}</p>

                                                                    {exchangeOrder ? (
                                                                        exchangeOrder == 'loading' ?
                                                                            <Row>
                                                                                <Column>
                                                                                    <Skeleton
                                                                                        className='skeleton'
                                                                                        width='10rem'
                                                                                        height='1.25rem'
                                                                                    />

                                                                                    <Skeleton
                                                                                        className='skeleton'
                                                                                        width='8rem'
                                                                                        height='1rem'
                                                                                    />

                                                                                    <Skeleton
                                                                                        className='skeleton'
                                                                                        width='8rem'
                                                                                        height='1rem'
                                                                                    />
                                                                                </Column>

                                                                                <Column>
                                                                                    <Skeleton
                                                                                        className='skeleton'
                                                                                        width='10rem'
                                                                                        height='1.25rem'
                                                                                    />

                                                                                    <Skeleton
                                                                                        className='skeleton'
                                                                                        width='8rem'
                                                                                        height='1rem'
                                                                                    />

                                                                                    <Skeleton
                                                                                        className='skeleton'
                                                                                        width='8rem'
                                                                                        height='1rem'
                                                                                    />
                                                                                </Column>
                                                                            </Row>
                                                                            :
                                                                            <Row fill='width' a='start'>
                                                                                <Column fill='width'>
                                                                                    <Row fill='width'>
                                                                                        <p className="simple-label">{t('status')}</p>

                                                                                        <p><b>{t(exchangeOrder.status)}</b></p>
                                                                                    </Row>

                                                                                    <Row fill='width'>
                                                                                        <p className="simple-label">{t('ats.slippage.label')}</p>

                                                                                        <Tooltip
                                                                                            placement="top"
                                                                                            title={t('ats.slippage.desc')}
                                                                                            align={'center'}
                                                                                        >
                                                                                            {infoSvg}
                                                                                        </Tooltip>

                                                                                        <p><b>{percentageFormatter({ v: exchangeOrder.slippage })}</b></p>
                                                                                    </Row>

                                                                                    <Row>
                                                                                        <p>{exchangeOrder.timedelta ? +exchangeOrder.timedelta.toFixed() : '-'}</p>

                                                                                        <p className="simple-label">
                                                                                            {t('delay-signal-to-order')}
                                                                                        </p>
                                                                                    </Row>
                                                                                </Column>

                                                                                <Column fill='width'>
                                                                                    <Row fill='width'>
                                                                                        <p className="simple-label">{t('total')}</p>

                                                                                        <p>
                                                                                            <b>
                                                                                                {exchangeOrder?.amount && exchangeOrder?.price
                                                                                                    ? (currencyFormatter(exchangeOrder.amount * exchangeOrder.price))
                                                                                                    : '-'}
                                                                                            </b>
                                                                                        </p>


                                                                                        <p className="simple-label">{exchangeOrder?.fee?.currency ?? '-'}</p>
                                                                                    </Row>

                                                                                    <Row fill='width'>
                                                                                        <p className="simple-label">{t('fee.s')}</p>

                                                                                        <p><b>{currencyFormatter(exchangeOrder?.fee?.cost)}</b></p>

                                                                                        <p className="simple-label">{exchangeOrder?.fee?.currency ?? '-'}</p>
                                                                                    </Row>

                                                                                    <Row fill='width'>
                                                                                        <p className="simple-label">{t('filled')}</p>

                                                                                        <p>
                                                                                            <b>
                                                                                                {
                                                                                                    t('x-of-y')
                                                                                                        .replace('{y}', exchangeOrder?.filled)
                                                                                                        .replace('{x}', exchangeOrder?.amount)
                                                                                                }

                                                                                                &nbsp;

                                                                                                ({exchangeOrder?.filled && exchangeOrder?.amount
                                                                                                    && percentageFormatter({
                                                                                                        v: exchangeOrder.filled / exchangeOrder.amount,
                                                                                                        positiveSignal: false
                                                                                                    })
                                                                                                })
                                                                                            </b></p>
                                                                                    </Row>
                                                                                </Column>
                                                                            </Row>)
                                                                        : <p>
                                                                            <b>{t('no-details')}</b>
                                                                        </p>
                                                                    }
                                                                </Column>
                                                            </Row>

                                                            <Title
                                                                txt={t('condition.p')}
                                                                variation='tertiary'
                                                            />

                                                            <Row
                                                                a='start'
                                                                fill='width'
                                                                fit='height'
                                                            >
                                                                {Object.entries(results).map(([conditionName, value], index) => {

                                                                    let iterationKey = exchangeSlug + ':' + pair + ':' + timeframe + '-' + conditionName;

                                                                    conditionName = conditionName
                                                                        .replaceAll('_', '-')
                                                                        .replace('condition-', '');


                                                                    let params = Object.entries(value)
                                                                        .sort((a, b) => a[0].localeCompare(b[0]))
                                                                        .map(([key, value], index) => {

                                                                            key = key
                                                                                .replaceAll('_', '-')
                                                                                .replace(conditionName + '-', '');

                                                                            return (
                                                                                <Row
                                                                                    key={iterationKey + '-' + index}
                                                                                    fit='all'
                                                                                    w='24'
                                                                                >
                                                                                    <Tooltip
                                                                                        placement="top"
                                                                                        title={t(`ats.${key}.desc`)}
                                                                                        align={'center'}
                                                                                    >
                                                                                        <p
                                                                                            className='w-16'
                                                                                            style={{ textAlign: 'right', opacity: .75 }}
                                                                                        >
                                                                                            {t(`ats.${key}.label`)}
                                                                                        </p>
                                                                                    </Tooltip>

                                                                                    <p className='w-8'>
                                                                                        <b>{+value ? `${value}`.slice(0, 8) : value}</b>
                                                                                    </p>
                                                                                </Row>
                                                                            );
                                                                        });
                                                                    return (
                                                                        <Card
                                                                            id={iterationKey}
                                                                            key={iterationKey}
                                                                            variation='condition-resume'
                                                                        >
                                                                            <Row>
                                                                                <p>
                                                                                    <b>{t(`ats.${conditionName}.label`)}</b>
                                                                                </p>

                                                                                <Tooltip
                                                                                    placement="top"
                                                                                    title={t(`ats.${conditionName}.desc`)}
                                                                                >
                                                                                    {infoSvg}
                                                                                </Tooltip>
                                                                            </Row>

                                                                            <hr />

                                                                            <Column className='condition-resume-params'>
                                                                                {params}
                                                                            </Column>
                                                                        </Card>
                                                                    );
                                                                })}
                                                            </Row>
                                                        </Column>
                                                    </>
                                                );
                                            }))
                                    }
                                </Column>
                            }
                        </>

                    </Panel>
                </Row>
            </Layout >
        </Page >
    );
};

export default AtsReports;
